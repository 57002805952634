import React from 'react';
// @ts-ignore
import {Message} from '@moadigitalworks/boutique-app/dist/components/Message';
import {AddressAutoComplete, FormButton, Input} from '../../../../components/ui';
import {FormProps} from '../../../../interfaces';

export default function BankAccountRender({errors,formData,submitLoading,onChangeInput,onChangeField,onSubmit}:FormProps):JSX.Element{
	return (
		<div className='w-100  flex direction-column align-center mt-3'>
			<h2>Bank Account</h2>
			<form
				className='w-100 center'
				onSubmit={onSubmit}
			>
				<Input
					name='accountName'
					onChange={onChangeInput}
					label='Account Name'
					placeholder='Account Name'
					defaultValue={formData['accountName']}
				/>
				<Input
					name='phone'
					onChange={onChangeInput}
					label='Phone Number'
					placeholder='Phone Number'
					defaultValue={formData['phone']}
				/>
				<Input
					name='accountNumber'
					onChange={onChangeInput}
					label='Account Number'
					placeholder='Account Number'
					defaultValue={formData['accountNumber']}
				/>
				<Input
					name='accountRoutingNumber'
					onChange={onChangeInput}
					label='Routing Number'
					placeholder='Routing Number'
					defaultValue={formData['accountRoutingNumber']}
				/>
				<AddressAutoComplete
					name='address'
					onChange={onChangeField}
					label='Address (Choose from auto select results)'
					placeholder='Address, City, Zip Code'
					placesTypes={['street_address']}
				/>
				<Input
					name='addressLine2'
					onChange={onChangeInput}
					label='Address Line 2'
					placeholder=''
				/>
				<Input
					name='taxId'
					onChange={onChangeInput}
					className='mb-2'
					label='Tax ID'
					placeholder='Tax ID'
					defaultValue={formData['taxId']}
				/>

				<FormButton submitLoading={submitLoading}>Continue</FormButton>

				<div className='w-100 mt'>
					{errors.accountName && <Message message='Account Name name is required' color='red'/>}
					{errors.accountNumber && <Message message='Account Number name is required' color='red'/>}
					{errors.accountRoutingNumber && <Message message='Account Routing Number name is required' color='red'/>}
					{errors.address && <Message message='Address is required' color='red'/>}
					{errors.phone && <Message message='Phone is required' color='red'/>}
					{errors.taxId && <Message message='TaxId is required' color='red'/>}
					{errors.submitError && <Message message={errors.submitError} color='red'/>}
				</div>

			</form>
		</div>
	);
}
