import React,{createContext,useState} from 'react';
import {getEnvSettings} from '../actions/restActions';
import {User} from '../interfaces';
import {AxiosResponse} from 'axios';

export const EnvContext:React.Context<{user:null}> =createContext({user:null});
interface EnvProviderProps {
    children:JSX.Element
}
const EnvContextProvider=({children}:EnvProviderProps):JSX.Element=>{
	const [env,setEnv]:[any|null,React.Dispatch<React.SetStateAction<any | null>>] =useState<User|null>(null);

	const getEnv=():void=>{
		getEnvSettings()
		.then((res:AxiosResponse):void=>setEnv(res.data))
		.catch((e:Error):void=>console.log({e}));
	};
	const value:any={
		env,
		getEnv
	};
	return (
		<EnvContext.Provider value={value} >
			{children}
		</EnvContext.Provider>
	);
};
export default EnvContextProvider;
