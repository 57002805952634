import React, {ChangeEvent, useEffect, useState} from 'react';
import UsersRender from './UsersRender';
import {QueryResult, useLazyQuery, useQuery} from '@apollo/client';
import {query} from 'actions/queries';
import {Item, Place, Valet} from 'interfaces';
import {useSearchParams} from 'react-router-dom';

export const Users = ():JSX.Element=>{
	const [invitePopup,setInvitePopup]:Array<any>=useState(false);
	let [searchParams, setSearchParams] = useSearchParams();
	const placeId:string|null=searchParams.get('place');
	// @ts-ignore
	const {data:placesData,loading:placesLoading,error:placesError}:QueryResult=useQuery(query('getPlacesByCompanyAsOptions'),
		{
			variables:{
				limit: 100,
				skip: 0,
				sort: 'asc',
				valet:true
			}
		});

	// eslint-disable-next-line @typescript-eslint/typedef
	const [getValets,{data:valetsData,loading:valetsLoading,error:valetsError}]:any=useLazyQuery(query('getValets'),
		{
			variables:{
				limit: 8,
				skip: 0,
				place:placeId
			}
		});

	useEffect(():void=>{
		if(placeId){
			getValets();
		}
	},[placeId]);

	const changePlace = ({target}:ChangeEvent<HTMLSelectElement>):void =>{
		setSearchParams({place:target.value});
	};

	if(placesLoading) return <p>Loading...</p>;
	if(placesError) return <p>Error...</p>;
	const placesOptions:Item[]=placesData.getPlacesByCompany.map((place:Place):Item=>({
		label:place.name,
		_id:place.id
	}));

	const valets:Valet[]=!valetsData? []
		: valetsData.getCompanyUsersValet;
	return <UsersRender
		users={valets}
		places={placesOptions}
		valetsLoading={valetsLoading}
		valetsError={valetsError}

		invitePopup={invitePopup}
		setInvitePopup={setInvitePopup}
		onChangePlace={changePlace}
	/>;
};
