import React, {useEffect, useState} from 'react';
import InputFileRender from './InputFileRender';

interface InputFileProps {
	id:string
	label:string
	className?:string

	updateFile:(file:HTMLInputElement['files'])=>void
}

export const InputFile = ({updateFile,...props}:InputFileProps):JSX.Element=>{
	const [file,setFile]:any=useState(null);
	const onChangeFile=(target:React.ChangeEvent<HTMLInputElement>):void=> {
		setFile(target.currentTarget.files);
	};
	useEffect(():void=>{
		updateFile(file);
	},[file]);

	return <InputFileRender
		file={file}
		onChangeFile={onChangeFile}
		{...props}
	/>;
};
