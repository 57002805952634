import React, {useState} from 'react';
import CompanyRender from './CompanyRender';
import {NavigateFunction, useLocation, useNavigate} from 'react-router-dom';
import {useMutation} from '@apollo/client';
import {mutation} from 'actions/mutations';
import {nextStep} from '../tools';
import {FormField, FormHook, useForm} from 'hooks/useForm';
import {companyPicture} from 'actions/restActions';


export const Company = ():JSX.Element=>{
	const navigate:NavigateFunction=useNavigate();
	const {submitError,setSubmitError}:any=useState('');

	const [company,setCompany]:any=useState({
		name:'company.name'
	});
	const [companyPictureFile,setCompanyPictureFile]:any=useState(undefined);

	const {pathname}:any=useLocation();
	let step:string=pathname?.split('/').pop();

	const [createCompany]:any=useMutation(mutation('createCompany'),{
		refetchQueries:[
			'getUserJwt',
			'getCompanies'
		]
	});

	const updateCompanyPicture = (id:string):void=>{
		companyPicture(companyPictureFile,id)
		.then(():any=> navigate(nextStep(step)))
		.catch(():void=>console.log('Error uploading company'));
	};

	const createCompanyAction=async(data:any):Promise<void>=>{
		try {
			let newCompany:any= await createCompany({
				variables:{...data}
			});
			if (companyPictureFile) {
				updateCompanyPicture(newCompany.data.createCompany.id);
			}
			navigate('/onboarding/2');
		}
		catch (e) {
			setSubmitError(e||'Error has occurred');
		}
	};
	const initForm:Array<FormField>  = [
		{
			name:'name',
			required: true
		}
	];

	const {data:formData,errors,handleSubmitForm,onChangeInput,loading:submitLoading}:FormHook
		=useForm(initForm, createCompanyAction);

	const onChangeFile =(file?:HTMLInputElement['files']):void=>{
		if(!file){
			return ;
		}
		setCompanyPictureFile(file[0]);
		let imageUrl:string=window.URL.createObjectURL(file[0]);
		setCompany({
			...company,
			picture:imageUrl
		});
	};

	return <CompanyRender
		errors={{...errors,submitError}}
		submitLoading={submitLoading}
		formData={formData}
		company={company}

		onChangeInput={onChangeInput}
		onChangeFile={onChangeFile}
		onSubmit={handleSubmitForm}
	/>;
};
