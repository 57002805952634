import React from 'react';
// @ts-ignore
import {Select} from '@moadigitalworks/boutique-app/dist/components/ui/Select';
import {SelectProps} from 'interfaces';
import {color} from 'styles/colors';

export default function SelectRender({labelColor,options,...props}:SelectProps):JSX.Element{
	return (
		<Select
			options={options||[]}
			{...props}
			styles={{
				background:'0',
				height:'48px :laptop 45px :mobile 43px',
				color:'#fff',
				lineHeight:'1.5rem',
				borderRadius:'4px',
				border : '1px solid',
				fontColor: color.get('grey400'),

				labelFontSize:'0.75rem',
				labelFontColor: labelColor|| color.get('white'),
				labelFontWeight:'700'
			}}
		/>
	);
}
