import React from 'react';
import SideBarRender from './SideBarRender';
import {OrdersIcon, PlacesIcon, UsersIcon} from '../icons/sidebar';
import {Location, useLocation} from 'react-router-dom';

export const SideBar = ():JSX.Element=>{
	const {pathname}:Location= useLocation();

	let routes:Array<any> =[
		{
			icon:<PlacesIcon/>,
			label:'Locations',
			route:'/dashboard/locations'
		},
		{
			icon:<UsersIcon/>,
			label:'Users',
			route:'/dashboard/users'
		},
		// {
		// 	icon:<AnalyticsIcon/>,
		// 	label:'Analytics',
		// 	route:'/dashboard/analytics'
		// },
		{
			icon:<OrdersIcon/>,
			label:'Orders',
			route:'/dashboard/orders'
		}
	];
	return <SideBarRender
		currentRoute={pathname.split('/').pop()}
		routes={routes}
	/>;
};
