import React, {useContext} from 'react';
import UserCardRender from './UserCardRender';
import {Valet} from 'interfaces';
import {EnvContext} from 'context/envContext';

interface UserCardProps {
	valet:Valet
}

export const UserCard = ({valet}:UserCardProps):JSX.Element=>{
	const {env}:any=useContext(EnvContext);
	return <UserCardRender
		valet={valet}
		bucket={env?.bucket}
	/>;
};
