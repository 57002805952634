import React from 'react';

export default function LogoRender():JSX.Element{
	return (
		<svg
			width={140}
			height={23}
			viewBox="0 0 140 23"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M94.2455 11.1858C95.8186 8.25731 97.3707 5.32176 98.7191 2.28087C98.9017 1.77666 99.1598 1.30309 99.4845 0.876301C99.6041 0.706047 99.7658 0.569766 99.9538 0.480811C100.142 0.391855 100.35 0.353274 100.557 0.36885C100.765 0.384426 100.965 0.453622 101.137 0.569662C101.31 0.685702 101.449 0.844608 101.542 1.0308C101.83 1.48729 102.034 1.99293 102.245 2.47751C103.417 4.76696 104.478 7.11259 105.756 9.33181C107.582 13.0258 109.403 16.7175 111.22 20.4068C111.501 20.9616 111.796 21.5094 112.084 22.0642C111.592 22.1508 111.091 22.1673 110.595 22.1134C109.745 21.2355 109.408 20.0768 108.916 19.0093C108.537 18.1876 108.214 17.3449 107.849 16.5021C107.777 16.2863 107.633 16.1018 107.441 15.9802C107.249 15.8585 107.021 15.8072 106.795 15.835C102.544 15.835 98.2953 15.835 94.0488 15.835C93.4168 15.835 93.185 16.1721 92.9603 16.6496C92.0895 18.4896 91.1906 20.3226 90.3057 22.1555L88.8098 22.0923C89.0236 21.3818 89.3331 20.7037 89.7298 20.0768C91.2608 17.1061 92.7566 14.1495 94.2455 11.1858ZM106.992 14.6973L100.629 1.56454C100.081 1.7682 100.025 2.18255 99.8708 2.5056C98.3773 5.57224 96.8908 8.6459 95.4113 11.7266C95.032 12.5132 94.6528 13.2997 94.3017 14.1003C93.9997 14.8026 94.0629 14.8588 94.8284 14.9079H105.7C106.141 14.964 106.589 14.8933 106.992 14.7043V14.6973Z"
				fill="white"
			/>
			<path
				d="M33.3292 14.1565C33.584 13.8817 33.7778 13.5562 33.8981 13.2014C35.71 9.59164 37.5125 5.9819 39.3057 2.37216C39.5036 2.05514 39.6572 1.71253 39.7622 1.35385C39.8243 1.09299 39.9674 0.858478 40.1709 0.683855C40.3744 0.509233 40.628 0.403445 40.8952 0.381626C41.1625 0.359808 41.4299 0.423075 41.659 0.562379C41.8881 0.701682 42.0674 0.909888 42.171 1.15721C43.2947 3.38345 44.4464 5.58862 45.5701 7.81486C47.0074 10.6755 48.4401 13.5385 49.868 16.4038C49.9738 16.5822 50.0911 16.7534 50.2192 16.9165C50.8798 18.6273 51.698 20.273 52.6631 21.8325C52.2909 22.296 51.7502 22.0291 51.3007 22.1625C50.2754 20.4419 49.4818 18.6019 48.632 16.8041C48.5161 16.4776 48.292 16.2006 47.9969 16.0192C47.7017 15.8378 47.3534 15.7628 47.0098 15.8069C43.0067 15.8443 39.0037 15.8443 35.0007 15.8069C34.6716 15.7663 34.3389 15.8435 34.0613 16.0247C33.7836 16.206 33.5791 16.4795 33.4837 16.7971C32.7815 18.3983 31.9879 19.9574 31.2364 21.5375C31.0749 21.8676 30.9555 22.2398 30.478 22.2398C30.1619 22.2398 29.7757 22.3873 29.565 22.0361C29.3543 21.685 29.6071 21.5094 29.7476 21.2355C30.8911 19.26 31.9046 17.212 32.7815 15.1046C32.9149 14.7675 33.3152 14.5708 33.3292 14.1565ZM47.5154 14.8588C46.778 12.8643 41.567 2.2949 40.9209 1.56453L34.5091 14.8588H47.5154Z"
				fill="white"
			/>
			<path
				d="M120.089 0.756916C120.255 0.668398 120.436 0.613783 120.622 0.596289C120.809 0.578795 120.997 0.598775 121.175 0.65505C121.354 0.711325 121.52 0.802758 121.662 0.923974C121.805 1.04519 121.922 1.19374 122.007 1.36088C124.268 4.50009 126.579 7.61823 128.875 10.7364C129.507 11.6002 129.626 11.5932 130.28 10.7364C132.576 7.66037 134.915 4.61948 137.134 1.48729C137.306 1.1386 137.599 0.864374 137.958 0.715767C138.317 0.56716 138.718 0.55433 139.086 0.679665C139.171 1.11508 138.833 1.38195 138.623 1.64882C136.08 5.11108 133.552 8.57334 130.961 11.9935C130.423 12.6773 130.151 13.5332 130.195 14.4023C130.237 16.5724 130.195 18.7354 130.195 20.9055C130.195 21.2004 130.195 21.4883 130.195 21.7833C130.202 21.8455 130.195 21.9085 130.174 21.9675C130.153 22.0265 130.119 22.08 130.074 22.1239C130.03 22.1678 129.976 22.2011 129.916 22.2212C129.857 22.2412 129.794 22.2476 129.732 22.2398C129.423 22.2398 129.121 22.2398 129.072 21.8395C129.05 21.5496 129.05 21.2585 129.072 20.9687C129.072 18.8618 128.973 16.7971 129.072 14.7183C129.131 13.5123 128.718 12.3306 127.92 11.4246C126.171 9.19135 124.5 6.88786 122.758 4.64055C121.81 3.41857 121.066 2.08423 120.089 0.756916Z"
				fill="white"
			/>
			<path
				d="M63.4994 17.5766C63.4994 17.1201 63.5626 16.6637 63.5626 16.1721C63.5626 11.4621 63.5626 6.7474 63.5626 2.02805C63.5626 1.69798 63.5626 1.32577 63.5626 1.03081C63.5626 0.735847 63.8225 0.553253 64.1525 0.553253C64.4826 0.553253 64.7284 0.658592 64.7354 1.03783C64.7424 1.41706 64.7354 1.74011 64.7354 2.03507C64.7354 7.04002 64.7354 12.0473 64.7354 17.0569C64.6706 17.525 64.7067 18.0015 64.8411 18.4545C64.9756 18.9075 65.2053 19.3265 65.5149 19.6835C66.6316 21.1513 68.3241 21.2847 69.9042 21.3268C73.1488 21.4111 76.4004 21.3268 79.6519 21.3268C79.9469 21.3268 80.2348 21.3268 80.5298 21.3268C81.0916 21.3268 81.0987 21.6709 81.0916 22.0712C80.4666 22.0712 79.8416 22.1204 79.2165 22.1204L68.0783 22.1625C66.853 22.13 65.6758 21.679 64.7424 20.8844C64.3357 20.6014 64.011 20.2159 63.8013 19.767C63.5916 19.318 63.5044 18.8216 63.5486 18.3281C63.5503 18.0767 63.5339 17.8256 63.4994 17.5766Z"
				fill="white"
			/>
			<path
				d="M11.5444 22.4926C11.4926 22.4287 11.45 22.3577 11.418 22.2819L10.0907 19.6835C9.71848 18.9812 9.34625 18.2087 8.96 17.4783C8.67206 16.9375 8.40521 16.3898 8.1243 15.842C7.75209 15.1397 7.3869 14.3672 7.00064 13.6438C6.70568 13.089 6.45286 12.5202 6.14385 11.9724C5.83485 11.4246 5.55392 10.8628 5.28003 10.2939C4.89378 9.50035 4.48646 8.7138 4.07913 7.92724C3.82631 7.44266 3.55944 6.95808 3.32768 6.45946C2.99761 5.75718 2.62541 5.11108 2.30235 4.42986C2.05656 3.93124 1.81076 3.43262 1.53687 2.94102C1.34725 2.5969 1.16466 2.23874 0.989086 1.8876C0.813515 1.53645 0.581764 1.08699 0.39917 0.679666C0.434284 0.61646 0.483439 0.637525 0.525576 0.637525H6.17896C6.22693 0.630817 6.27575 0.640298 6.31773 0.664465C6.3597 0.688632 6.39244 0.726108 6.41072 0.770961C6.92339 1.81034 7.47116 2.82866 7.9768 3.86804C8.20856 4.33857 8.46138 4.80208 8.67909 5.27261C8.78443 5.47627 8.86871 5.68695 8.97406 5.88359C9.20581 6.34007 9.45161 6.78954 9.67634 7.239L10.9966 9.83745C11.3267 10.4836 11.6427 11.1367 11.9869 11.7758C12.331 12.4148 12.6189 13.1101 12.9771 13.7492C13.4616 14.6341 13.9111 15.54 14.3816 16.4389C14.4283 16.5137 14.4531 16.6001 14.4531 16.6882C14.4531 16.7764 14.4283 16.8628 14.3816 16.9375C13.9392 17.7803 13.5108 18.6301 13.0754 19.4728L11.5795 22.3873L11.5444 22.4926Z"
				fill="white"
			/>
			<path
				d="M15.2244 15.2099C15.1612 15.0975 15.112 15.0133 15.0699 14.929L13.5319 11.9022C13.251 11.3614 12.956 10.8206 12.6891 10.2729C12.5698 10.02 12.4363 9.77424 12.3099 9.52844C12.2809 9.47957 12.2655 9.42376 12.2655 9.36691C12.2655 9.31005 12.2809 9.25425 12.3099 9.20539C12.8226 8.20814 13.3212 7.20388 13.8409 6.19961C14.1569 5.58862 14.4589 4.97061 14.7819 4.35962C15.105 3.74864 15.4842 2.95505 15.8354 2.30193C16.1022 1.78926 16.3621 1.26957 16.6219 0.756906C16.6363 0.709998 16.6667 0.669593 16.7077 0.642668C16.7487 0.615743 16.7979 0.603984 16.8467 0.609422H22.5352H22.6686L15.2244 15.2099Z"
				fill="white"
			/>
		</svg>
	);
}