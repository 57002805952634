import React from 'react';

import {Avatar, FormButton, Input, InputFile} from 'components/ui';
import {Company, FormProps} from 'interfaces';
// @ts-ignore
import {Message} from '@moadigitalworks/boutique-app/dist/components/Message';

export interface CompanyDataRenderProps extends FormProps{
	company:Company

	onChangeFile:(file:HTMLInputElement['files'])=>void
}

export default function CompanyRender({company,errors,submitLoading,onChangeInput,onChangeFile,onSubmit}:CompanyDataRenderProps):JSX.Element{
	return (
		<div className='w-100  flex direction-column align-center mt-3'>
			<h2 className='mb'>Company</h2>
			<Avatar
				size='154px'
				user={{
					firstName: company.name,
					profilePicture: company.picture?{
						small:company.picture
					}:undefined
				}}
			/>
			<InputFile
				className='mt'
				id={'company-picture'}
				label={'Upload company image'}
				updateFile={onChangeFile}/>
			<form
				className='w-100 center'
				onSubmit={onSubmit}
			>
				<Input
					name='name'
					label='Company Name'
					placeholder='Name'

					onChange={onChangeInput}
				/>
				<FormButton submitLoading={submitLoading}>Continue</FormButton>

				<div className='w-100 mt'>
					{errors.name && <Message message='Company name is required' color='red'/>}
					{errors.submitError && <Message message={errors.submitError} color='red'/>}
				</div>
			</form>
		</div>
	);
}
