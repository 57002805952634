import React, {useContext} from 'react';
import HeaderRender from './HeaderRender';
import {UserContext} from '../../context/userContext';
import {useQuery} from '@apollo/client';
import {query} from '../../actions/queries';

export const Header = ():JSX.Element=>{
	const {user,error,loading}:any =useContext(UserContext);
	const {data,loading:jwtLoading,error:jwtError}:any=useQuery(query('getUserJwt'));

	if(loading || jwtLoading) {
		return <p>Loading....</p>;
	}
	if(error|| jwtError) {
		return <p>Error....</p>;
	}
	return <HeaderRender user={{...user,...data.getUserJwt}}/>;
};
