import React from 'react';
import PaginationRender from './PaginationRender';
import {useSearchParams} from 'react-router-dom';

export interface PaginationProps {
	max?:number
	className?:string
	showRange?:number
}

export const Pagination =({max=10,showRange=3, ...props}:PaginationProps):JSX.Element=>{

	const [searchParams, setSearchParams]:any = useSearchParams();

	const current:number=parseInt(searchParams.get('page')||'1')-1;

	let currentPage:number = current+1;
	let selectablePages:Array<number>= Array.from(Array(max).keys())
	.map((number:number):number=>number);

	let halfRange:number = Math.floor(showRange/2);
	let botLimit:number=currentPage-halfRange;
	botLimit=botLimit>=1?botLimit:1;

	let topLimit:number=botLimit+showRange;
	topLimit=topLimit>max?max:topLimit;
	botLimit= topLimit-showRange;

	selectablePages=selectablePages.slice(botLimit,topLimit);

	const onSelectPage=(page:number):void=>{
		setSearchParams({page});
	};

	return <PaginationRender
		{...props}
		current={currentPage}
		max={max}
		selectablePages={selectablePages}
		onClick={onSelectPage}
	/>;
};
