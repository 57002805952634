import React from 'react';

export default function VSymbolRender({...props}:React.ParamHTMLAttributes<SVGSVGElement>):JSX.Element{
	return (
		<svg
			{...props}
			width={86}
			height={84}
			viewBox="0 0 86 84"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M42.6143 83.5718C42.4166 83.3276 42.2542 83.0568 42.1319 82.7673L37.0671 72.8444C35.6468 70.1625 34.2265 67.2125 32.7526 64.4233C31.6539 62.3583 30.6356 60.2664 29.5637 58.1746C28.1434 55.4927 26.7499 52.5427 25.276 49.7804C24.1505 47.6617 23.1858 45.4894 22.0067 43.3975C20.8275 41.3057 19.7556 39.1602 18.7105 36.9879C17.2366 33.9574 15.6823 30.9537 14.128 27.95C13.1633 26.0995 12.145 24.249 11.2606 22.3449C10.0011 19.663 8.58087 17.1957 7.34816 14.5943C6.41023 12.6902 5.47232 10.7861 4.4272 8.90876C3.70365 7.59465 3.00691 6.2269 2.33696 4.88597C1.66701 3.54504 0.782685 1.82864 0.0859375 0.273159C0.219927 0.0317913 0.407493 0.112233 0.568281 0.112233H22.1406C22.3237 0.0866177 22.51 0.122821 22.6701 0.215111C22.8303 0.307401 22.9552 0.450513 23.025 0.621794C24.9812 4.59095 27.0714 8.47967 29.0009 12.4488C29.8852 14.2457 30.8499 16.0157 31.6807 17.8126C32.0827 18.5903 32.4043 19.3948 32.8062 20.1458C33.6906 21.889 34.6285 23.6054 35.486 25.3218L40.5241 35.2447C41.7836 37.712 42.9895 40.2061 44.3026 42.6466C45.6157 45.0871 46.7143 47.7422 48.081 50.1826C49.9301 53.5618 51.6452 57.0214 53.4406 60.4542C53.6189 60.7397 53.7134 61.0696 53.7134 61.4062C53.7134 61.7429 53.6189 62.0728 53.4406 62.3583C51.7523 65.5765 50.1177 68.8216 48.4562 72.0398L42.7483 83.1696L42.6143 83.5718Z"
				fill="white"
			/>
			<path
				d="M56.6564 55.7609C56.4153 55.3318 56.2277 55.01 56.0669 54.6882L50.1981 43.1293C49.1262 41.0643 48.0007 38.9993 46.9824 36.9074C46.5268 35.9419 46.0177 35.0033 45.5353 34.0646C45.4244 33.878 45.3658 33.6649 45.3658 33.4478C45.3658 33.2307 45.4244 33.0176 45.5353 32.831C47.4915 29.0227 49.3942 25.1877 51.3772 21.3526C52.5831 19.0194 53.7354 16.6593 54.9681 14.3261C56.2009 11.9929 57.6479 8.96239 58.9878 6.46826C60.0062 4.5105 60.9977 2.52593 61.9892 0.568165C62.0442 0.389036 62.16 0.234736 62.3166 0.131917C62.4731 0.0290968 62.6607 -0.0158105 62.8468 0.00495601H84.5531H85.0623L56.6564 55.7609Z"
				fill="white"
			/>
		</svg>
	);
}