import {ApolloClient, createHttpLink, InMemoryCache, ApolloLink, HttpLink, from} from '@apollo/client';
import {addHeaders} from '../tools';
import {onError} from '@apollo/client/link/error';
import {refreshJWT} from './restActions';
import {AxiosError} from 'axios';

const link:ApolloLink = createHttpLink({
	uri:  process.env.REACT_APP_GRAPHQL,
	credentials:'include',
	headers:addHeaders
});

const httpLink:ApolloLink = new HttpLink({
	uri:  process.env.REACT_APP_GRAPHQL,
	credentials:'include',
	headers:addHeaders
});


const errorLink:any = onError(({ graphQLErrors, networkError, operation, forward }:any):any => {
	if (graphQLErrors) {
		console.log({error:graphQLErrors[0].extensions});
		if (graphQLErrors[0].extensions.code==='FORBIDDEN') {
			console.log('Error no auth');
			refreshJWT()
			.then(():void =>{
				operation.setContext({
					headers: addHeaders
				});
				console.log('operation');
				return forward (operation);
			})
			.catch((e:AxiosError):void=>console.log(e));
		}
		graphQLErrors.forEach(({message, locations, path}: any): any =>
			console.log(
				`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
			)
		);
	}
	if (networkError) console.log(`[Network error]: ${networkError}`);
});

export const client =():ApolloClient<any>=> new ApolloClient({
	cache: new InMemoryCache(),
	link :from([errorLink, httpLink])
});