import React from 'react';

export interface PaginationRenderProps {
	current:number
	max?:number
	selectablePages:number[]
	className?:string

	onClick: (page:number)=>void
}
export default function PaginationRender({current,selectablePages,className,onClick}:PaginationRenderProps):JSX.Element{

	return (
		<div
			className={`flex ${className}`}
		>

			{selectablePages.map((page:number):JSX.Element=>
				<div
					className={`flex justify-center align-center m-half cursor-pointer ${page===current?'green-valay':'white'}`}
					onClick={():void=>onClick(page)}
					key={page}
					style={{
						width:'40px',
						border:'1px solid #F0F0F0',
						boxSizing: 'border-box',

						boxShadow: '0 3px 20px rgba(0, 0, 0, 0.2)',
						borderRadius: '5px'
					}}
				>
					<p className='f-700 f-large'>{page}</p>
				</div>
			)}
		</div>
	);
}
