import React from 'react';
import ReactDOM, {Root} from 'react-dom/client';
import './styles';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from 'react-router-dom';
import {ApolloProvider} from '@apollo/client';
import {client} from './actions/apolloClient';
import UserContextProvider from './context/userContext';
import EnvContextProvider from './context/envContext';
import PlacesContextProvider from './context/placesContext';

const root:Root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
	<React.StrictMode>
		<ApolloProvider client={client()}>
			<EnvContextProvider>
				<UserContextProvider>
					<PlacesContextProvider>
						<BrowserRouter>
							<App />
						</BrowserRouter>
					</PlacesContextProvider>
				</UserContextProvider>
			</EnvContextProvider>
		</ApolloProvider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
