import React, {Ref, useRef, useState} from 'react';
import InviteValetRender from './InviteValetRender';
import useOnClickOut from 'hooks/useOnClickOut';
import {FormField, FormHook, useForm} from 'hooks/useForm';
import {useMutation} from '@apollo/client';
import {mutation} from 'actions/mutations';
import {Params, useParams, useSearchParams} from 'react-router-dom';

interface CategoryPopupProps{
	setHidden:()=>void
}
export const InviteValet = ({setHidden}:CategoryPopupProps):JSX.Element=>{

	const {id}:Params = useParams();
	const [submitError,setSubmitError]:any = useState('');
	let [searchParams] = useSearchParams();
	const formRef:Ref<Element> = useRef(null);
	const handleHidePopUp = ():void=> setHidden();
	useOnClickOut(formRef,handleHidePopUp);

	const initForm:Array<FormField>  = [
		{
			name:'firstName',
			required: true
		},
		{
			name:'lastName',
			required: true
		},
		{
			name:'email',
			default: '',
			required: true,
			pattern: /^\S+@\S+\.\S+$/
		},
		{
			name:'place',
			default: id||searchParams.get('place')||'',
			required: true
		}
	];


	const [inviteValet]:any=useMutation(mutation('InviteValet'),{
		refetchQueries:['getCompanyUsersValet'],
		awaitRefetchQueries:true
	});

	const handleInviteValet= async(data:any):Promise<void>=>{
		try {
			await inviteValet({
				variables:{
					...data
				}
			});
			setHidden();
		}
		catch (e) {
			setSubmitError('Error has occurred: ' + e);
		}
	};
	const {data,loading,errors,onChangeInput,handleSubmitForm}:FormHook=useForm(initForm,handleInviteValet);

	return <InviteValetRender
		formRef = {formRef}
		submitLoading={loading}
		errors={{...errors,submitError: submitError}}
		formData={data}
		placeId={!!id}
		onSubmit={handleSubmitForm}
		onChangeInput={onChangeInput}
	/>;
};

