import React, {useContext, useEffect, useState} from 'react';
import LoginRender from './LoginRender';
import {LoginData} from '../../interfaces';
import {useForm,FormField, FormHook} from '../../hooks/useForm';
import {NavigateFunction, useNavigate} from 'react-router-dom';
import {UserContext} from '../../context/userContext';

export const Login = ():JSX.Element=>{
	const navigate:NavigateFunction=useNavigate();
	const [submitError,setSubmitError]:any = useState('');
	const {userLogin,user,refresh}:any=useContext(UserContext);
	const initForm:Array<FormField>  = [
		{
			name:'email',
			default: '',
			required: true,
			pattern: /^\S+@\S+\.\S+$/
		},
		{
			name:'password',
			default: '',
			required: true
		}
	];
	useEffect(():void=>{
		refresh();
		console.log({user});

		if(user){
			navigate('/dashboard');
		}
	},[user]);
	const loginAction=async(loginData:LoginData):Promise<any> =>{
		console.log('Login', {loginData});
		try {
			console.log('--login');
			await userLogin(loginData);
			console.log('--navigate');
			navigate('/dashboard/places');
		}
		catch (e) {
			setSubmitError(e||'Error on Login');
		}
	};

	const {errors,handleSubmitForm,onChangeInput,loading}:FormHook=useForm(initForm, loginAction);

	return <LoginRender
		submitLoading={loading}
		errors={{...errors,submitError}}
		onChangeInput={onChangeInput}
		onSubmit={handleSubmitForm}
	/>;
};