import React from 'react';

export default function AnalyticsRender():JSX.Element{
	return (
		<>
			<h1>
				Analytics
			</h1>
		</>
	);
}