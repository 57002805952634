import React from 'react';
// @ts-ignore
import {Message} from '@moadigitalworks/boutique-app/dist/components/ui';
import {Popup} from 'portals/Popup';
import {AddressAutoComplete, CheckBox, FormButton} from 'components/ui';
import {color} from 'styles/colors';
import {FormProps} from 'interfaces';

interface PopupRenderProps extends FormProps{
	formRef:any
}


export default function AddPlaceRender({formRef,errors,submitLoading,onChangeField,onChangeInput,onSubmit}:PopupRenderProps):JSX.Element {
	return (
		<Popup>
			<div
				className='grows w-100 flex direction-column align-center justify-center pver-2 '
				style={{backgroundColor:'rgba(0,0,0,0.8)'}}
			>
				<div
					ref={formRef}
					style={{alignSelf:'center'}}
					className='w-400px white t-black br flex direction-column'
				>
					{/*<CloseIcon*/}
					{/*	className='align-self-end mt mr-2'*/}
					{/*	action={():void=>console.log('to do: close popup')}*/}
					{/*/>*/}
					<form
						className='w-100 flex direction-column align-center pt-3 pr pb-2 pl'
						onSubmit={onSubmit}
					>
						<h4 className='f-large'>Add a new place</h4>
						<p className='f-300 center'>
							Search the place below. All the place data will be imported automatically.</p>
						<AddressAutoComplete
							labelColor={color.get('grey500')}
							name='address'
							onChange={onChangeField}
							label='Address (Choose from auto select results)'
							placeholder='Search place with Google'
							placesTypes={['parking']}
							id={'autocomplete-places'}
						/>
						<div className='grid2'>
							<CheckBox
								label={'Valet'}
								name='valet'
								id='valet'
								onChange={onChangeInput}
							/>

							<CheckBox
								label={'Parking'}
								checked={true}
								readOnly={true}
							/>
						</div>
						<FormButton
							submitLoading={submitLoading}
							spinnerColor={color.get('green-valay')}
							className='w-100'
							color={color.get('green-valay')}
						>
							Save
						</FormButton>
						{errors?<div className='mt'>
							{errors.address?.required && <Message message='Address is required' color='red'/>}
							{errors.valet?.pattern && <Message message='Please add a valid email' color='red'/>}
							{errors.submitError && <Message message={errors.submitError} color='red'/>}
						</div>: null}
					</form>
				</div>
			</div>
		</Popup>
	);
}
