import React from 'react';
// @ts-ignore
import {Button} from '@moadigitalworks/boutique-app/dist/components/ui';
import {ButtonProps} from '../../../interfaces';

export default function ButtonRender({label,onClick,type,to,children,className, color='#00B5AC'}:ButtonProps):JSX.Element{
	return (
		<Button
			type={type}
			to={to}
			onClick={onClick}
			className = {className}
			buttonType = 'submit'
			styles={{
				width:'186px',
				height: '48px',
				fontFamily: '"Sora", sanse-serif',
				color:color
			}}
		>
			{label||children}
		</Button>
	);
}
