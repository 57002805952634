import React, {ChangeEventHandler, FormEvent, FormEventHandler, useEffect, useState} from 'react';

export interface FormField {
	name:string
	required?:boolean
	validate?:boolean
	default?:string|number|boolean|Array<string>
	value?:string|number
	array?:boolean
	pattern?:any
}

export interface FormHook{
	data:any
	errors:any
	loading:boolean
	initForm: ()=>void
	handleSubmitForm: FormEventHandler<HTMLFormElement>
	onChangeInput:ChangeEventHandler<HTMLInputElement>
	onChangeSelect:ChangeEventHandler<HTMLSelectElement>
	onChangeTextArea:ChangeEventHandler<HTMLTextAreaElement>
	onChangeList:(name:string,values:Array<string>)=>void
	onChangeField:(value:string|number|boolean, name:string)=>void
}

interface Target<Type>{
	target:React.ChangeEvent<Type>
}



export const useForm = (fields: Array<FormField>, submitAction: (data: any) => void):FormHook => {
	let initializedData:any = {};
	const [data,setData]:any = useState(initializedData);
	const [errors,setErrors]:any = useState({});
	const [loading,setLoading]:any = useState(false);

	const initForm = ():void=>{
		fields.reduce((acc:any,currentField:FormField ):void=>{
			initializedData[currentField.name]=currentField.default || null;
		},initializedData);
		setData(initializedData);
	};
	useEffect(():void=>{
		initForm();
	},[]);

	const onChangeInput = ({target}: Target<HTMLInputElement>['target']):void|null =>{

		if (data[target.name]===undefined){
			return null;
		}
		if(target.type === 'checkbox') {
			return setData({...data, [target.id]: target.checked});
		}
		setData({...data, [target.name]:target.value});
	};

	const onChangeField = (value:string|number|boolean,name:string):void =>{
		setData({...data, [name||'badInput']:value});
	};

	const onChangeSelect = ({target}: Target<HTMLSelectElement>['target']):void|null =>{
		if (data[target.name]===undefined){
			return null;
		}
		setData({...data, [target.name]:target.value});
	};

	const onChangeTextArea = ({target}: Target<HTMLTextAreaElement>['target']):void|null =>{
		if (data[target.name]===undefined){
			return null;
		}
		setData({...data, [target.name]:target.value});
	};

	const onChangeList = (name:string,values:Array<string>):void=>{
		setData({...data, [name]:values});
	};

	const fieldError = (field:FormField,errors:any):void=>{
		if(field.array && data[field.name].length<1){
			errors[field.name]={required: true};
		}
		if(field.required && !data[field.name]){
			errors[field.name]={required: true};
		}else if(field.validate && !data[field.name]){
			errors[field.name]={validate: true};
		}
		if(field.pattern && !field.pattern.test(data[field.name])){
			errors[field.name]={
				pattern:true
			};
		}
	};

	const validateForm=():boolean=>{
		let errors:any = {};
		fields.forEach((field:FormField):void=> fieldError(field, errors));
		setErrors(errors);
		return  !(Object.keys(errors).length);
	};

	const handleSubmitForm=async(e:FormEvent):Promise<void> =>{
		e.preventDefault();
		setLoading(true);
		if(validateForm()){
			await submitAction(data);
		}
		setLoading(false);
	};
	return{data,errors,loading,initForm,handleSubmitForm,onChangeInput,onChangeSelect,onChangeTextArea,onChangeList,onChangeField};
};
