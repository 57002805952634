import React from 'react';
import {Logo} from '../Logo';
import {Avatar, SelectCompany, SelectPlaceType} from '../ui';
import {User} from '../../interfaces';
import {LogoutButton} from '../LogoutButton';
import {Link} from 'react-router-dom';

interface HeaderRenderProps {
	user:User
}

export default function HeaderRender({user}:HeaderRenderProps):JSX.Element{
	return (
		<div className='w-100 max-w-1600px flex justify-between align-center h-80px phor-2' >
			<div className='flex align-center'>
				<Link to='/dashboard/places'>
					<Logo/>
				</Link>
				{user?.company ?
					<>
						<SelectCompany className='ml'/>
						<SelectPlaceType className='ml'/>
					</>:null
				}
			</div>
			<div className='flex align-center'>
				<p>{user.firstName} {user.lastName}</p>
				<Avatar user={user} size='48px' className='ml'/>
				<LogoutButton className='ml'/>
			</div>
		</div>
	);
}
