import React from 'react';
import {InputProps} from '../../../interfaces';

export default function CheckBoxRender({label,...props}:InputProps):JSX.Element{
	return (
		<div className='valay-checkbox'>
			<input
				{...props}
				type={'checkbox'}
			/>
			<label>{label}</label>
		</div>

	);
}
