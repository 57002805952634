import React from 'react';
import {ButtonProps} from '../../interfaces';
import LogoutButtonRender from './LogoutButtonRender';
import {logout} from '../../actions/restActions';
import {useNavigate,NavigateFunction} from 'react-router-dom';

export const LogoutButton =({...props}:ButtonProps):JSX.Element=>{
	const navigate:NavigateFunction=useNavigate();
	let logoutAction =():void=>{
		console.log('logout');
		logout().then(():void=>navigate('/login'));
	};
	return <LogoutButtonRender onClick={logoutAction} {...props}/>;
};