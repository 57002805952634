import React, {ReactNode, useContext, useEffect} from 'react';
import {Navigate} from 'react-router-dom';
import {UserContext} from '../../context/userContext';

interface ProtectedRouteProps {
	children:ReactNode
	redirectTo:string
}

export const ProtectedRoute = ({children,redirectTo}:ProtectedRouteProps):JSX.Element=>{
	const {user,refresh,loading}:any = useContext(UserContext);
	useEffect(():void=>{
		refresh();
	},[]);

	if (loading)
		return <p>Loading ...</p>;
	if(!user){
		return <Navigate to={redirectTo} replace/>;
	}
	return <>{children}</>;
};
