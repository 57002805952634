import React from 'react';
// @ts-ignore
import {CardBase} from '@moadigitalworks/boutique-app/dist/components';
import {User, Valet} from 'interfaces';
import {Avatar} from 'components/ui';
import {Link} from 'react-router-dom';

interface UserCardRenderProps {
	valet:Valet
	bucket?:string
	boolean?:boolean
}
export default function UserCardRender({valet}:UserCardRenderProps):JSX.Element{
	const {firstName,lastName,email}:User =valet.user;
	return (
		<CardBase
			className='flex max-w-500px direction-column  mb-2 mobile:grid2 h-auto'
			$width='100%'
			padding='12px 12px 16px 12px'
		>
			<Link
				to={'/'}
				className='grows flex direction-column align-center t-black'
			>
				<Avatar user={valet.user} size={'10rem'}/>
				<div>
					<h6>{firstName} {lastName}</h6>
					<p>{email}</p>
					<p>{valet.place?.name}</p>
				</div>
			</Link>
		</CardBase>
	);
}
