import React from 'react';
import {AuthLayout} from '../../components';
import {Input,FormButton,Button} from '../../components/ui';
import {FormProps} from '../../interfaces';
// @ts-ignore
import {Message} from '@moadigitalworks/boutique-app/dist/components/ui';

export default function SignupRender({onSubmit,errors,submitLoading,onChangeInput}:FormProps):JSX.Element{
	return (
		<AuthLayout >
			<>
				<h2>Signup</h2>
				<form
					className='w-100 max-w-500px flex direction-column align-center pver-4'
					onSubmit={onSubmit}
				>
					<div className='w-100 grid2 col-gap'>
						<Input
							name='firstName'
							label = 'First Name'
							placeholder='First Name'
							onChange={onChangeInput}
						/>
						<Input
							name='lastName'
							label = 'Last Name'
							placeholder='Last Name'
							onChange={onChangeInput}
						/>
					</div>
					<Input
						name='email'
						label = 'Email'
						placeholder='Email'
						onChange={onChangeInput}
					/>
					<Input
						name='password'
						label = 'Password'
						placeholder='Password'
						type='password'
						onChange={onChangeInput}
					/>

					<FormButton submitLoading={submitLoading} >
							Signup
					</FormButton>


					{errors?<div className={'mt'}>
						{errors.firstName?.required && <Message message='First Name is required' color='red'/>}
						{errors.lastName?.required && <Message message='Last Name  is required' color='red'/>}
						{errors.email?.required && <Message message='Email is required' color='red'/>}
						{errors.email?.pattern && <Message message='Please add a valid email' color='red'/>}
						{errors.password && <Message message='Password is required' color='red'/>}
						{errors.submitError && <Message message={errors.submitError} color='red'/>}
					</div>:null}
				</form>
				<div className='flex direction-column align-center'>
					<p className='mb'>Already have an account?</p>
					<Button
						label='login'
						type='link'
						to='/login'
					/>
				</div>
			</>
		</AuthLayout>
	);
}