import React,{createContext,useState} from 'react';
import {login, logout, refreshJWT, signup, userData} from '../actions/restActions';
import {LoginData, SignupData, User} from '../interfaces';

export const UserContext:React.Context<userContext> =createContext({});
interface userProviderProps {
	children:JSX.Element
}

interface userContext {
	user?:User|null
	loading?:boolean|null
	error?:Error|null

	setUser?:(value:User)=>void
	unSetUser?:()=>void

	userLogin?:(data:LoginData)=>Promise<any>,
	userSignup?:(data:SignupData)=>Promise<any>
	refresh?:()=>Promise<any>
}

const ADMIN_ROLE_ID:string ='2e7905db-a2f2-4202-95cd-aaf75532c249';

const UserContextProvider=({children}:userProviderProps):JSX.Element=>{
	const [user,setUser]:any =useState<User|null>(null);
	const [loading,setLoading]:any=useState<boolean|null>(true);
	const [error,setError]:any =useState<Error|null>(null);

	const userLogin=async(data:LoginData):Promise<any> => {
		const userData:any =await login(data).catch();
		console.log({userData});
		if(userData?.role.id !== ADMIN_ROLE_ID){
			await logout();
			throw 'You must be a company Admin';
		}
		setUser(userData);
	};

	const requestUser=async():Promise<any> => setUser(await userData());
	const refresh=async():Promise<any> => {
		setLoading(true);
		refreshJWT()
		.then(():Promise<any>=>requestUser())
		.catch((e:Error):void=>setError(e))
		.finally(():void=>setLoading(false));
	};
	const value:userContext={
		user,
		loading,
		error,

		setUser:(value:User):void=>{
			setUser(value);
		},
		unSetUser:():void=>{
			setUser(null);
		},
		userLogin,
		userSignup:async(data:SignupData):Promise<any> => {
			setUser(await signup(data));
			console.log(data);
		},
		refresh
	};
	return (
		<UserContext.Provider value={value} >
			{children}
		</UserContext.Provider>
	);
};
export default UserContextProvider;
