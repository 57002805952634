import React from 'react';
// @ts-ignore
import {Button} from '@moadigitalworks/boutique-app/dist/components/ui';
import {color} from '../../styles/colors';
import {ButtonProps} from '../../interfaces';

export default function LogoutButtonRender({className,onClick}:ButtonProps):JSX.Element{
	return (
		<Button
			className={'uppercase '+className}
			onClick={onClick}
			styles={{
				width:'100px :laptop 100px :mobile 80px',
				height: '24px :laptop 24px :mobile 20px',
				fontFamily: '"Sora", sanse-serif',
				color:color.get('teal-valet')
			}}
		>
			Logout
		</Button>
	);
}