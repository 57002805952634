import gql from 'graphql-tag';

const queries:any={
	'getUserJwt': gql`
	query getUserJwt{
	  getUserJwt {
		company {
		  createdAt
		  id
		  name
		  stripeId
		  updatedAt
		}
		createdAt
		email
		emailVerified
		firstName
		id
		lastName
		password
		profilePicture {
		  small
		}
		role {
		  createdAt
		  id
		  label
		  updatedAt
		}
		stripeId
		updatedAt
	  }
	}`,
	'getCompanies': gql`
	query getCompanies($limit: Float!, $skip: Float!) {
	  getCompanies(limit: $limit, skip: $skip) {
		createdAt
		id
		name
		stripeId
		updatedAt
	  }
	}`,
	'getPlacesByCompany': gql`
		query getPlacesByCompany(
		$limit: Float!,
		$skip: Float!,
		$sort: String!,
		$valet: Boolean
	  ) {
	  getPlacesByCompany(
		limit: $limit
		skip: $skip
		sort: $sort
		valet: $valet
	  ) {
		addressComponents {
		  longName
		  shortName
		  types
		}
		administrativeAreaLevel1
		administrativeAreaLevel2
		auxDistanceMeters
		available
		city
		company {
		  createdAt
		  id
		  name
		  stripeId
		  updatedAt
		}
		country
		cover {
		  large
		  small
		}
		createdAt
		description
		email
		formattedAddress
		hourlyRate
		id
		location {
		  coordinates
		  type
		}
		name
		numberOfReviews
	
		phone
		placeId
		rating
		timeZone
		types
		updatedAt
		valet
	  
		website
	  }
	}`,
	'getPlacesByCompanyAsOptions': gql`
		query getPlacesByCompanyAsItem($valet:Boolean){
		  getPlacesByCompany(
			limit:100
			skip: 0
			sort: "asc"
			valet: $valet
		  ) {
		   id
			name
			}
		}`,
	'getValets': gql`
		query getCompanyUsersValet($limit:Float!, $place:String!,$skip:Float!) {
	  getCompanyUsersValet(limit:$limit, place: $place, skip: $skip) {
		createdAt
		id
		updatedAt
		 place{
		  name
		  id
		  placeId
		}
		user {
		  createdAt
		  email
		  emailVerified
		  firstName
		  id
		  lastName
		  password
		  phone
		  phoneCountryCode
		  stripeId
		  updatedAt
		}
		userRole {
		  createdAt
		  id
		  label
		  updatedAt
		}
	  }
	}`,
	'getPlace':gql`
		query getPlace ($id:String!){
		  getPlace(id: $id) {
			city
			name
			company {
			  createdAt
			  id
			  name
			  stripeId
			  updatedAt
			}
			country
			cover {
			  large
			  small
			}
			createdAt
			description
			email
			formattedAddress
			hourlyRate
			id
		  }
		}
	`,
	'getCompanyOrders':gql`
		query getCompanyOrders{
		  getCompanyOrders {
			createdAt
			discount
			email
			id
			name
			paid
			paymentType {
			  createdAt
			  id
			  label
			  updatedAt
			}
			subtotal
			tip
			total
			transactionId
			transferId
			updatedAt
		  }
		}
	`
};


export const query=(value:string):any=>{
	return queries[value];
};
