import React from 'react';
import {InviteValet} from 'popups/InviteValet';
import {Place, User} from 'interfaces';
import {Button} from 'components/ui';
import {color} from 'styles/colors';

interface PlaceDetailRenderProps {
	place:Place
	invitePopup:boolean
	valets :User[]

	setInvitePopup:(visible:boolean)=>void
}

export default function PlaceDetailRender({place,invitePopup,valets,setInvitePopup}:PlaceDetailRenderProps):JSX.Element{
	const {name,cover}:Place=place;
	console.log({valets});
	return (
		<>
			{invitePopup?<InviteValet setHidden={():any=>setInvitePopup(false)}/>:null}
			<section className='flex justify-between'>
				<h4>{name}</h4>
				<Button
					className='f-700 f-uppercase mt-0'
					color={color.get('green-valay')}
					onClick={():any=>setInvitePopup(true)}
				>
					+ Invite Valet
				</Button>

			</section>
			<section className='mt w-100 max-w-1200px flex direction-column align-center'>
				<div className='w-100 h-50 black'>
					{cover &&
					<picture className='w-100 h-50 black'>
						<source
							media='(max-width:640px)'
							srcSet={`${cover?.small}`}
						/>
						<source
							media='(max-width:1600px)'
							srcSet={`${cover?.large}`}
						/>
						<img
							className='w-100 h-100 black obj-cover'
							src={cover ? `${cover?.large}` : ''}
							alt={'place cover image'}
						/>
					</picture>
					}
				</div>
				<div>
					{valets.map((valet:User):JSX.Element=>
						<h4 key={valet.id}>{valet.email}</h4>
					)}
				</div>
			</section>
		</>
	);
}
