import React from 'react';
import AvatarRender, {MoaDWAvatarProps} from './AvatarRender';
import {Company} from 'interfaces';

interface AvatarProps extends React.ParamHTMLAttributes<HTMLElement> {
	user:{
		profilePicture?:{
			small:string
		},
		firstName:string
		company?:Company
	}
	size:string
}
export const Avatar = ({user,...props}:AvatarProps):JSX.Element=>{
	let moaDWUser:MoaDWAvatarProps['user'] = {
		...user,
		profilePicture: user.profilePicture? {
			url:user.profilePicture.small
		} : undefined
	};

	return <AvatarRender
		user={moaDWUser}
		{...props}
	/>;
};
