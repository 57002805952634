import React, {useContext, useEffect} from 'react';
import AdminDataRender from './AdminDataRender';
import {UserContext} from '../../../../context/userContext';
import {FormField, FormHook, useForm} from '../../../../hooks/useForm';
import {useMutation} from '@apollo/client';
import {mutation} from '../../../../actions/mutations';
import {NavigateFunction, useLocation, useNavigate} from 'react-router-dom';
import {nextStep} from '../tools';
import {profilePicture} from '../../../../actions/restActions';

export const AdminData = ():JSX.Element=>{
	const navigate:NavigateFunction=useNavigate();
	const {user,refresh}:any=useContext(UserContext);

	const {pathname}:any=useLocation();
	let step:string=pathname.split('/').pop();
	const [updateUser]:any=useMutation(mutation('updateUser'));

	const updateUserAction=(data:any):void=>{
		if(data?.firstName === user?.firstName || data?.lastName === user.lastName){
			navigate(nextStep(step));
		}
		updateUser({
			variables:{...data}
		})
		.then(():void=> {
			refresh();
			navigate(nextStep(step));

		})
		.catch();
	};
	const initFormArray:Array<FormField>  = [
		{
			name:'firstName',
			default: user?.firstName,
			required: true
		},
		{
			name:'lastName',
			default: user?.lastName,
			required: true
		}
	];
	const {data:formData,errors,handleSubmitForm,onChangeInput,loading:submitLoading,initForm}:FormHook=useForm(initFormArray, updateUserAction);
	useEffect(():void=>{
		initForm();
	},[user]);

	const onChangeFile =(e:any):void=>{
		let file:any= e.target.files[0];
		profilePicture(file).then(():any=>refresh());
	};
	console.log({user,formData});
	if (!user){return <p>Loading</p>;}
	return <AdminDataRender
		user={user}
		errors={errors}
		submitLoading={submitLoading}
		formData={formData}

		onChangeFile={onChangeFile}
		onChangeInput={onChangeInput}
		onSubmit={handleSubmitForm}
	/>;
};
