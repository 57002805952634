import React from 'react';

interface LoadSpinnerProps {
	color?:string
}

export const LoadingSpinner = ({color}:LoadSpinnerProps):JSX.Element=>{
	return <div className={`lds-ellipsis`}>
		<div style={{backgroundColor:color}}/>
		<div style={{backgroundColor:color}}/>
		<div style={{backgroundColor:color}}/>
		<div style={{backgroundColor:color}}/>
	</div>;
};

export default LoadingSpinner;
