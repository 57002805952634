import React, {ChangeEvent, Ref, useContext, useRef, useState} from 'react';
import AddPlaceRender from './AddPlaceRender';
import useOnClickOut from 'hooks/useOnClickOut';
import {FormField, FormHook, useForm} from 'hooks/useForm';
import {useMutation} from '@apollo/client';
import {mutation} from 'actions/mutations';
import {query} from 'actions/queries';
import {PlacesContext} from 'context/placesContext';


interface CategoryPopupProps{
	setHidden:()=>void
}
export const AddPlace = ({setHidden}:CategoryPopupProps):JSX.Element=>{
	const formRef:Ref<Element> = useRef(null);
	const [submitError,setSubmitError]:any = useState('');
	const {setIsValet}:any=useContext(PlacesContext);
	const [valet,setValet]:any=useState(false);


	const handleHidePopUp = ():void=>{
		console.log({focused: document.activeElement?.id});
		if(document.activeElement?.id !== 'autocomplete-places'){
			setHidden();
		}
	};
	useOnClickOut(formRef,handleHidePopUp);
	const initForm:Array<FormField>  = [
		{
			name:'address',
			required: true
		},
		{
			name:'valet',
			default:false
		}
	];

	const [addPlace]:any=useMutation(mutation('createPlace'),{
		refetchQueries:[
			{
				query:query('getPlacesByCompany'),
				variables:{
					limit: 8,
					skip: 0,
					sort: 'asc',
					valet
				}
			},
			{
				query:query('getPlacesByCompanyAsOptions'),
				variables:{
					valet:true
				}
			}
		],
		awaitRefetchQueries:true
	});


	const handleUpdate= async(data:any):Promise<void>=>{
		try {
			await addPlace({
				variables:{
					placeId:data.address,
					valet:!!data.valet,
					hourlyRate:8
				}
			});
			setHidden();
			console.log({valet:!!data.valet});
			setIsValet(!!data.valet);
		}
		catch (e) {
			setSubmitError('Error has occurred' + e);
		}
	};
	const {loading,errors,onChangeField,onChangeInput,handleSubmitForm}:FormHook=useForm(initForm,handleUpdate);

	const ValetHandler= (e:ChangeEvent<HTMLInputElement>):void=>{
		console.log({
			checked:e.target.checked,
			value:e.target.value
		});
		setValet(e.target.checked);
		onChangeInput(e);
	};

	const onChangePlaceAutoComplete =(value:any,name:string):void=>{
		onChangeField(value,name);
	};

	return <AddPlaceRender
		formRef = {formRef}
		submitLoading={loading}
		errors={{...errors,submitError: submitError}}

		onSubmit={handleSubmitForm}
		onChangeInput={ValetHandler}
		onChangeField={onChangePlaceAutoComplete}
	/>;
};

