interface Color {
	name:string,
	hex:string
}

const colors:Array<Color>=[
	{
		name:'dark-blue-valay',
		hex:'#171F42'
	},
	{
		name:'darker-blue-valay',
		hex:'#0A1130'
	},
	{
		name:'green-valay',
		hex:'#75BC34'
	},
	{
		name:'teal-valet',
		hex:'#00B5AC'
	},
	{
		name:'transparent',
		hex:'transparent'
	},
	{
		name:'white',
		hex:'#ffffff'
	},
	{
		name:'black',
		hex:'#000000'
	},
	{
		name:'grey900',
		hex:'#313536\n'
	},
	{
		name:'grey800',
		hex:'#313633'
	},
	{
		name:'grey700',
		hex:'#474747'
	},
	{
		name:'grey500',
		hex:'#808080'
	},
	{
		name:'grey400',
		hex:'#9EA6A7'
	},
	{
		name:'grey300',
		hex:'#C4C4C4'
	},
	{
		name:'grey100',
		hex:'#F0F0F0'
	},
	{
		name:'red',
		hex:'#C9ACAC'
	}
];

const mapAux:Map<string, string> =new Map<string, string>();
const color:Map<string, string> = colors.reduce((map:Map<string, string>,color:Color):Map<string, string> =>{
	map.set(color.name,color.hex);
	return map;
},mapAux);

// eslint-disable-next-line @typescript-eslint/typedef
let style=colors.map(
	// eslint-disable-next-line @typescript-eslint/explicit-function-return-type,@typescript-eslint/typedef
	({name,hex})=>{
		return (
			`.t-${name}, .hover\\:t-${name}:hover{ color: ${hex}; }
.${name}, .hover\\:${name}:hover{ background-color: ${hex}; fill: ${hex} }
.b-${name}, .hover\\:b-${name}:hover, .focus\\:b-${name}:focus{ border-color: ${hex}; stroke: ${hex}; }
.sh-4-${name}, .hover\\:sh-4-${name}:hover, .focus\\:sh-4-${name}:focus{box-shadow: 0px 0px 4px ${hex};}
.sh-10-${name}, .hover\\:sh-10-${name}:hover, .focus\\:sh-10-${name}:focus{filter: drop-shadow(0px 0px 10px ${hex});}
.sh-20-${name}, .hover\\:sh-20-${name}:hover, .focus\\:sh-20-${name}:focus{filter: drop-shadow(0px 0px 20px ${hex});}
@media (max-width: 640px) {
.mobile\\:${name} { background-color: ${hex}}
.mobile\\:b-${name} { border-color: ${hex}; stroke: ${hex};}
.mobile\\:sh-10-${name}{filter: drop-shadow(0px 0px 10px ${hex});}
}
@media (max-width: 1024px) {
.tablet\\:${name} { background-color: ${hex}}
.tablet\\:b-${name} { border-color: ${hex}; stroke: ${hex};}
.tablet\\:sh-10-${name}{filter: drop-shadow(0px 0px 10px ${hex});}
}`
		);
	}
).join(' \n\n');


// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function addcss(css:string){
	// eslint-disable-next-line @typescript-eslint/typedef
	let head=document.getElementsByTagName('head')[0];
	// eslint-disable-next-line @typescript-eslint/typedef
	let s=document.createElement('style');
	s.setAttribute('type','text/css');
	// eslint-disable-next-line no-mixed-spaces-and-tabs
	              // the world
	s.appendChild(document.createTextNode(css));

	head.appendChild(s);
}

addcss(style);

export{color};
