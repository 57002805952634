import React, {useContext} from 'react';
import PlaceCardRender from './PlaceCardRender';
import {Place} from 'interfaces';
import {EnvContext} from 'context/envContext';

interface PlaceCardProps {
	place:Place
}

export const PlaceCard = ({place}:PlaceCardProps):JSX.Element=>{
	const {env}:any=useContext(EnvContext);
	return <PlaceCardRender
		place={place}
		bucket={env?.bucket}
	/>;
};
