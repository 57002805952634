import React from 'react';

export const OrdersIcon = ():JSX.Element => {
	return(
		<svg
			width={20}
			height={27}
			viewBox="0 0 20 27"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M5.5 7H14.9453C15.1562 7 15.3281 6.92969 15.4609 6.78906C15.6016 6.64844 15.6719 6.47656 15.6719 6.27344C15.6719 6.07031 15.6016 5.90234 15.4609 5.76953C15.3281 5.62891 15.1562 5.55859 14.9453 5.55859H5.5C5.28125 5.55859 5.10156 5.62891 4.96094 5.76953C4.82812 5.90234 4.76172 6.07031 4.76172 6.27344C4.76172 6.47656 4.82812 6.64844 4.96094 6.78906C5.10156 6.92969 5.28125 7 5.5 7ZM5.5 10.3984H11.043C11.2539 10.3984 11.4258 10.3281 11.5586 10.1875C11.6992 10.0469 11.7695 9.875 11.7695 9.67188C11.7695 9.46875 11.6992 9.30078 11.5586 9.16797C11.4258 9.02734 11.2539 8.95703 11.043 8.95703H5.5C5.28125 8.95703 5.10156 9.02734 4.96094 9.16797C4.82812 9.30078 4.76172 9.46875 4.76172 9.67188C4.76172 9.875 4.82812 10.0469 4.96094 10.1875C5.10156 10.3281 5.28125 10.3984 5.5 10.3984ZM5.67578 22H14.3359C15.375 22 15.8945 21.4805 15.8945 20.4414V13.9258C15.8945 12.8789 15.375 12.3555 14.3359 12.3555H5.67578C5.18359 12.3555 4.79688 12.4883 4.51562 12.7539C4.24219 13.0195 4.10547 13.4102 4.10547 13.9258V20.4414C4.10547 20.957 4.24219 21.3477 4.51562 21.6133C4.79688 21.8711 5.18359 22 5.67578 22ZM0.144531 22.4336C0.144531 23.6602 0.445313 24.5781 1.04688 25.1875C1.65625 25.7969 2.56641 26.1016 3.77734 26.1016H16.2227C17.4336 26.1016 18.3398 25.7969 18.9414 25.1875C19.5508 24.5781 19.8555 23.6602 19.8555 22.4336V4.65625C19.8555 3.4375 19.5508 2.51953 18.9414 1.90234C18.3398 1.28516 17.4336 0.976562 16.2227 0.976562H3.77734C2.56641 0.976562 1.65625 1.28516 1.04688 1.90234C0.445313 2.51953 0.144531 3.4375 0.144531 4.65625V22.4336ZM2.03125 22.3984V4.69141C2.03125 4.10547 2.18359 3.65625 2.48828 3.34375C2.80078 3.02344 3.26172 2.86328 3.87109 2.86328H16.1289C16.7383 2.86328 17.1953 3.02344 17.5 3.34375C17.8125 3.65625 17.9688 4.10547 17.9688 4.69141V22.3984C17.9688 22.9844 17.8125 23.4336 17.5 23.7461C17.1953 24.0586 16.7383 24.2148 16.1289 24.2148H3.87109C3.26172 24.2148 2.80078 24.0586 2.48828 23.7461C2.18359 23.4336 2.03125 22.9844 2.03125 22.3984Z"
				fill="white"
			/>
		</svg>
	);
};