import React, {useEffect, useRef, useState} from 'react';
import {Company, Place} from '../interfaces';

interface GooglePlace {

	administrativeAreaLevel1:string
	administrativeAreaLevel2:string
	auxDistanceMeters:string|null
	available:boolean
	city:string
	company: Company
	country:string
	createdAt?:string
	description?:string|null
	email:string|null
	formattedAddress:string
	hourlyRate:number
	id:string
	place_id:string
	location:Location
	name:string
	numberOfReviews?:string|null
	phone?:string|null
	placeId:string
	rating?:number|null
	timeZone:string
	types?:Array<string>
	updatedAt:string
	valet:boolean
	website?:string|null
}

export interface GooglePlaceAtuComplete {
    autocompleteInput:React.Ref<HTMLInputElement>
	googlePlace:GooglePlace
}

const usePlaceAutocomplete = (placeTypes:Array<string>,onPlaceChanged:(place:Place)=>void):GooglePlaceAtuComplete => {
	const [googlePlace,setGooglePlace]:any = useState<Place|undefined>(undefined);
	const autocompleteInput:React.Ref<HTMLInputElement> =useRef(null);
	let autocomplete:any;

	const handlePlaceChanged=():void=> {
		const place:Place = autocomplete.getPlace();
		setGooglePlace(place);
		// @ts-ignore
		console.log({place, idPlace:place.place_id});
	};

	useEffect(():void=>{
		// @ts-ignore
		autocomplete = new google.maps.places.Autocomplete(
			autocompleteInput.current,
			{ types: placeTypes }
		);
		autocomplete.addListener('place_changed', handlePlaceChanged);
	},[]);

	return {autocompleteInput,googlePlace};
};

export default usePlaceAutocomplete;