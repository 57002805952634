import React from 'react';
// @ts-ignore
import {Avatar} from '@moadigitalworks/boutique-app/dist/components/ui';

export interface MoaDWAvatarProps extends  React.ParamHTMLAttributes<HTMLElement>{
	user:{
		profilePicture?:{
			url:string
			type?:string
		}
		firstName:string
	}
	size:string
}

export default function AvatarRender({user,size, ...props}:MoaDWAvatarProps):JSX.Element{
	return (
		<Avatar
			{...props}
			user={user}
			size={size}
		/>
	);
}
