import React from 'react';
import RatingRender from './RatingRender';
import StarIcon from '../../icons/StarIcon';

interface RatingProps {
	size?:number
	rating:number
	maxRating:number
}

export const Rating =({rating=0,maxRating,size}:RatingProps):JSX.Element=>{
	let fraction:number = Math.round((rating/maxRating)*5);

	let stars: Array<JSX.Element>=[];
	for(let i:number=0; i<5;i++){

		stars.push(
			<StarIcon
				key={'star'+i}
				size={size}
				className='mr-quarter'
				active={i<fraction}
			/>
		);
	}
	return <RatingRender
		stars={stars}
	/>;
};
