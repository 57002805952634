import React from 'react';
import {Link} from 'react-router-dom';

interface Route{
	icon:JSX.Element
	label:string
	route:string
}

interface HeaderRenderProps {
	routes:Array<Route>
	currentRoute?:string
}

export default function SideBarRender({routes,currentRoute}:HeaderRenderProps):JSX.Element{
	return (
		<aside className='w-200px pt-4 pl-2' >
			{routes.map((r:Route):JSX.Element=>
				<Link
					className='flex t-white mb-3 align-center'
					to={r.route}
					key={r.label}
				>
					<div className='w-2rem'>
						{r.icon}
					</div>
					<p className='ml-2'>
						{r.label}
						{r.label.toLowerCase() === currentRoute &&<hr/> }
					</p>

				</Link>
			)}
		</aside>
	);
}
