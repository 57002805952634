import React from 'react';
import {Header} from 'components/Header';
import {Outlet} from 'react-router-dom';

export default function OnBoardingRender():JSX.Element{
	return (
		<>
			<Header/>
			<div className='w-100 max-w-500px mb-3'>
				<Outlet/>
			</div>
		</>
	);
}