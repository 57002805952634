import React, {useContext, useEffect, useState} from 'react';
import PlacesRender from './PlacesRender';
import {QueryResult, useQuery} from '@apollo/client';
import {query} from 'actions/queries';
import {PlacesContext} from 'context/placesContext';
import {useSearchParams} from 'react-router-dom';

export const Places = ():JSX.Element=>{
	const [addPopup,setAddPopUp]:Array<any>=useState(false);
	const {isValet}:any=useContext(PlacesContext);
	let [searchParams] :any=useSearchParams();
	let page:any=searchParams.get('page');
	let currentPage:number= page? parseInt(page)-1 : 0;
	// @ts-ignore
	const {data,loading,error,refetch:refetchPlaces}:QueryResult=useQuery(query('getPlacesByCompany'),
		{
			variables:{
				limit: 8,
				skip: currentPage*8,
				sort: 'asc',
				valet:isValet
			}
		});
	useEffect(():void=>{
		refetchPlaces({
			limit: 8,
			skip: currentPage*8,
			sort: 'asc',
			valet:isValet
		});
	},[currentPage]);

	if(loading) return <p>Loading...</p>;
	if(error) return <p>Error...</p>;
	return <PlacesRender
		places={data.getPlacesByCompany}
		addPopup={addPopup}
		setAddPopup={setAddPopUp}
	/>;
};
