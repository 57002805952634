import React, {ChangeEventHandler, SetStateAction} from 'react';
import {Item, Valet} from 'interfaces';
import {color} from 'styles/colors';
import {Button, Select} from 'components/ui';
import {LoadingSpinner} from 'components/LoadingSpinner';
import {InviteValet} from 'popups/InviteValet';
import {UserCard} from './componetns';

interface UsersRenderProps {
	users:Array<Valet>
	invitePopup:boolean
	places:Item[]
	valetsLoading:boolean
	valetsError:boolean

	onChangePlace:ChangeEventHandler<HTMLSelectElement>
	setInvitePopup:(visible:boolean)=>SetStateAction<boolean>
}

export default function UsersRender({users,invitePopup,places,onChangePlace,valetsLoading,setInvitePopup}:UsersRenderProps):JSX.Element{

	return (
		<>
			{invitePopup?<InviteValet setHidden={():any=>setInvitePopup(false)}/>:null}

			<section className='flex justify-between'>
				<h4>Users</h4>

				<Button
					className='f-700 f-uppercase mt-0'
					color={color.get('green-valay')}
					onClick={():any=>setInvitePopup(true)}
				>
					+ Invite Valet
				</Button>

			</section>
			<section className='mt'>
				<Select
					labelColor={color.get('grey500')}
					options={places}
					name='place'
					placeholder='Select a place'
					onChange={onChangePlace}
				/>
				{!valetsLoading ?
					<div className='grid3 mt col-gap'>{

						users.map((valet: Valet): JSX.Element =>
							<UserCard key={valet.user.id} valet={valet}/>
						)

					}
					</div>
					:
					<LoadingSpinner/>
				}
			</section>
		</>
	);
}
