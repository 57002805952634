import React from 'react';
import OrdersRender from './OrdersRender';
import {QueryResult, useQuery} from '@apollo/client';
import {query} from 'actions/queries';

export const Orders = ():JSX.Element=>{
	const {data,loading,error}:QueryResult=useQuery(query('getCompanyOrders'));

	if(loading) return <p>Loading...</p>;

	return <OrdersRender
		orders={data.getCompanyOrders||[]}
	/>;
};
