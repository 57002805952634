import React, {SetStateAction} from 'react';
import {Button, Pagination} from 'components/ui';
import {color} from 'styles/colors';
import {AddPlace} from 'popups/AddPlace';
import {Place} from 'interfaces';

import {PlaceCard} from './components/PlaceCard';

interface PlacesRenderProps {
	addPopup:boolean
	places:Array<Place>

	setAddPopup:(visible:boolean)=>SetStateAction<boolean>
}

export default function PlacesRender({addPopup,places,setAddPopup}:PlacesRenderProps):JSX.Element{
	return (
		<>
			{addPopup?<AddPlace setHidden={():any=>setAddPopup(false)}/>:null}


			<section className='flex justify-between align-center mb-2'>
				<h4>Parking Locations</h4>
				<Button
					className='f-700 f-uppercase mt-0'
					color={color.get('green-valay')}
					onClick={():any=>setAddPopup(true)}
				>
					+ ADD place
				</Button>
			</section>
			<section className='grows grid4  laptop:grid3 mobile:grid2 col-gap '>
				{places.map((p:Place):JSX.Element=>
					<PlaceCard
						key={p.id}
						place={p}
					/>
				)}
			</section>
			<Pagination
				className='align-self-center'
			/>
		</>
	);
}
