import React from 'react';
// @ts-ignore
import {Button} from '@moadigitalworks/boutique-app/dist/components/ui';
import {color} from 'styles/colors';
import LoadingSpinner from 'components/LoadingSpinner';

export interface FormButtonProps extends React.ParamHTMLAttributes<HTMLInputElement>{
	color?:string
	submitLoading?:boolean
	spinnerColor?:string
}

export default function FormButtonRender({children,color:colorButton,spinnerColor,submitLoading, ...props}:FormButtonProps):JSX.Element{


	return !submitLoading? (
		<Button
			{...props}
			className = 'w-100 f-uppercase'
			buttonType = 'submit'
			styles={{
				width:'100% :laptop 100% :mobile 100%',
				height: '48px :laptop 48px :mobile 32px',
				fontWeight:'700',
				fontSize: '16px :mobile 12px',
				margin: '20px 0 0 0 ',
				fontFamily: '"Sora", sanse-serif',
				color:colorButton ||color.get('teal-valet')
			}}
		>
			{children}
		</Button>
	)
		:
		<div className='w-100 flex justify-center'>
			<LoadingSpinner color={spinnerColor} />
		</div>
	;
}
