import React from 'react';

export interface UploadFileProps {
	id:string
	label:string
	className?:string
	file:HTMLInputElement['files']


	onChangeFile:(e:React.ChangeEvent<HTMLInputElement>)=>void
}

export default function InputFileRender({id,label,className,onChangeFile}:UploadFileProps):JSX.Element{
	return (
		<>
			<label
				htmlFor={id}
				className={`b-bottom b-grey800 t-white cursor-pointer f-minimal f-400 ${className}`}
			>
				{label}
			</label>
			<input id={id} type='file' className='hidden ' onChange={onChangeFile}/>
		</>
	);
}
