import React from 'react';

export const PlacesIcon = ():JSX.Element => {
	return(
		<svg
			width={26}
			height={25}
			viewBox="0 0 26 25"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M12.9766 24.5352C13.2422 24.5352 13.4961 24.4609 13.7383 24.3125C13.9883 24.1719 14.2305 23.9023 14.4648 23.5039L16.6328 19.8828H20.5469C21.7109 19.8828 22.6914 19.6758 23.4883 19.2617C24.2852 18.8398 24.8867 18.2305 25.293 17.4336C25.707 16.6367 25.9141 15.6641 25.9141 14.5156V5.53906C25.9141 4.39062 25.707 3.41797 25.293 2.62109C24.8867 1.82422 24.2852 1.21875 23.4883 0.804688C22.6914 0.382812 21.7109 0.171875 20.5469 0.171875H5.45312C4.28906 0.171875 3.30859 0.382812 2.51172 0.804688C1.71484 1.21875 1.10937 1.82422 0.695312 2.62109C0.289062 3.41797 0.0859375 4.39062 0.0859375 5.53906V14.5156C0.0859375 15.6641 0.289062 16.6367 0.695312 17.4336C1.10937 18.2305 1.71484 18.8398 2.51172 19.2617C3.30859 19.6758 4.28906 19.8828 5.45312 19.8828H9.32031L11.4883 23.5039C11.7227 23.9023 11.9609 24.1719 12.2031 24.3125C12.4531 24.4609 12.7109 24.5352 12.9766 24.5352ZM12.9766 22.6133L11.0195 18.9453C10.8242 18.5703 10.6289 18.3203 10.4336 18.1953C10.2383 18.0625 9.93359 17.9961 9.51953 17.9961H5.45312C4.26562 17.9961 3.38672 17.7031 2.81641 17.1172C2.25391 16.5234 1.97266 15.6523 1.97266 14.5039V5.53906C1.97266 4.39844 2.25391 3.53516 2.81641 2.94922C3.38672 2.35547 4.26562 2.05859 5.45312 2.05859H20.5469C21.7266 2.05859 22.6016 2.35547 23.1719 2.94922C23.7422 3.53516 24.0273 4.39844 24.0273 5.53906V14.5039C24.0273 15.6523 23.7422 16.5234 23.1719 17.1172C22.6016 17.7031 21.7266 17.9961 20.5469 17.9961H16.4336C16.0195 17.9961 15.7148 18.0625 15.5195 18.1953C15.3242 18.3203 15.1289 18.5703 14.9336 18.9453L12.9766 22.6133Z"
				fill="white"
			/>
		</svg>
	);
};