import React, {useContext, useState} from 'react';
import SignupRender from './SignupRender';
import {useForm,FormField,FormHook} from 'hooks/useForm';
import {NavigateFunction, useNavigate} from 'react-router-dom';
import {SignupData} from 'interfaces';
import {UserContext} from 'context/userContext';

export const Signup = ():JSX.Element=>{
	const {userSignup}:any=useContext(UserContext);
	const navigate:NavigateFunction=useNavigate();
	const [submitError,setSubmitError]:any = useState();
	const initForm:Array<FormField>  = [
		{
			name:'firstName',
			default: '',
			required: true
		},
		{
			name:'lastName',
			default: '',
			required: true
		},
		{
			name:'email',
			default: '',
			required: true,
			pattern: /^\S+@\S+\.\S+$/
		},
		{
			name:'password',
			default: '',
			required: true
		}
	];

	const signupAction=async(signupData:SignupData):Promise<void>=>{
		console.log('signup',{signupData});
		try {
			await userSignup(signupData);
			navigate('/onboarding/1');
		}
		catch(e:any){
			setSubmitError(e||'Error on Login');
		}
	};

	const {handleSubmitForm,errors,onChangeInput,loading:submitLoading}:FormHook = useForm(initForm,signupAction);



	return <SignupRender
		submitLoading={submitLoading}
		errors={{...errors,submitError}}

		onChangeInput={onChangeInput}
		onSubmit={handleSubmitForm}
	/>;
};
