import {useEffect, useState} from 'react';

export default function useOnClickOut(ref:any, action:any):any {
	const [suspended,setSuspended]:any = useState<boolean>(false);
	useEffect(():() => void => {
		function handleClickOutside(event:any):void {

			if (ref.current && !ref.current.contains(event.target) && !suspended) {
				action();
			}
		}

		// Bind the event listener
		document.addEventListener('mousedown', handleClickOutside);
		return ():void => {
			// Unbind the event listener on clean up
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [ref]);
	return { setSuspended };
}
