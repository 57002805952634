import React, {useEffect, useState} from 'react';
import PlaceDetailRender from './PlaceDetailRender';
import {QueryResult, useLazyQuery, useQuery} from '@apollo/client';
import {query} from 'actions/queries';
import {Params, useParams} from 'react-router-dom';
import {User} from 'interfaces';

export const PlaceDetail = ():JSX.Element=>{
	const [invitePopup,setInvitePopup]=useState(false);
	const [valets,setValets]=useState<User[]>([]);
	const {id}:Params = useParams();
	// @ts-ignore
	const {data:placeData,loading:placeLoading,error:placeError}:QueryResult=useQuery(query('getPlace'),
		{
			variables:{
				id
			}
		});

	// eslint-disable-next-line @typescript-eslint/typedef
	const [getValets,{data:valetsData,loading:valetsLoading,error:valetsError}]=useLazyQuery(query('getValets'),
		{
			variables:{
				limit: 8,
				//skip: currentPage*8,
				skip: 0,
				place:id
			}
		});

	useEffect(():void=>{
		if(placeData?.getPlace){
			getValets()
			.catch((e:Error):void=>console.log(e));
		}
	},[placeLoading]);

	useEffect(():void=>{
		if(valetsData){
			const valets:User[]=valetsData.getCompanyUsersValet.map((valet:any):User=>valet.user);
			setValets(valets);
		}
	},[valetsData]);

	if(placeLoading) return <p>Loading...</p>;
	if(placeError) return <p>Error...</p>;
	return <PlaceDetailRender
		place={placeData.getPlace}
		invitePopup={invitePopup}
		setInvitePopup={setInvitePopup}
		valets={valets}
	/>;
};
