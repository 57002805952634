import React, {ChangeEvent, useContext} from 'react';
import SelectPlaceTypeRender from './SelectPlaceTypeRender';
import {Item, SelectProps} from '../../../interfaces';
import {PlacesContext, PlacesContextValue} from '../../../context/placesContext';
import {useSearchParams} from 'react-router-dom';


export const SelectPlaceType = ({...props}:SelectProps):JSX.Element=>{
	const {setIsValet:setValet,isValet}:PlacesContextValue=useContext(PlacesContext);
	const [searchParams, setSearchParams]:any = useSearchParams();
	const change= ({target}:ChangeEvent<HTMLSelectElement>):void=>{
		setSearchParams({page:1});
		setValet(target.value==='valet');
	};
	const options:Array<Item>=[
		{_id:'valet',label:'Valet'},
		{_id:'parking',label:'Parking'}
	];

	return <SelectPlaceTypeRender
		{...props}
		isValet={isValet?'valet':'parking'}
		options={options}
		onChange={change}
	/>;
};
