import React from 'react';
// @ts-ignore
import {CardBase} from '@moadigitalworks/boutique-app/dist/components';
import {Place} from 'interfaces';
import {PlaceIcon} from 'components/PlaceIcon';
import {Rating} from 'components/ui';
import {Link} from 'react-router-dom';

interface PlaceCardRender{
	place:Place
	bucket?:string
	boolean?:boolean
}
export default function PlaceCardRender({place}:PlaceCardRender):JSX.Element{
	const {name,valet,rating,cover}:Place =place;
	return (
		<CardBase
			className='flex w-100 direction-column h-400px mb-2 mobile:grid2 h-auto'
			$width='100%'
			padding='12px 12px 16px 12px'
		>
			<Link
				to={place.id}
				className='h-100 flex direction-column'
			>

				<div className='w-100 h-50 black'>
					{cover &&
					<picture className='w-100 h-50 black'>
						<source
							media='(max-width:640px)'
							srcSet={`${cover?.small}`}
						/>
						<source
							media='(max-width:1600px)'
							srcSet={`${cover?.small}`}
						/>
						<img
							className='w-100 h-100 black obj-cover'
							src={cover ? `${cover?.small}` : ''}
							alt={'place cover image'}
						/>
					</picture>
					}
				</div>

				<div className='grows flex direction-column justify-between'>
					<div className='flex justify-between mt'>
						<h5 className='mr-2 t-grey700 laptop:h6'>{name}</h5>
						<PlaceIcon valet={valet}/>
					</div>
					<div>

						<Rating rating={rating||0} maxRating={10}/>
					</div>

				</div>
			</Link>
		</CardBase>
	);
}
