import React, {useState} from 'react';
import {NavigateFunction, useNavigate} from 'react-router-dom';
import {QueryResult, useMutation, useQuery} from '@apollo/client';
import {FormField, FormHook, useForm} from 'hooks/useForm';
import BankAccountRender from './BankAccountRender';
import {mutation} from 'actions/mutations';
import {query} from 'actions/queries';

export const BankAccount = ():JSX.Element=>{
	const navigate:NavigateFunction=useNavigate();
	const [submitError,setSubmitError]:any=useState();


	const {data:user,loading,error}:QueryResult=useQuery(query('getUserJwt'));
	const [createCompanyBankAccount]:any=useMutation(mutation('createCompanyBankAccount'),
		{
			refetchQueries:[
				'getUserJwt'
			]
		});

	const createBankAccountAction=async(data:any):Promise<void> =>{
		console.log(data);
		let company:any =user.getUserJwt.company;
		if(company.stripeId){
			setSubmitError('This company already has a bank account');
		}else{
			try {
				data.id=company?.id;
				let newAccount:any =await createCompanyBankAccount({
					variables:{...data}
				});
				console.log({newAccount});
				navigate('/dashboard');
			}
			catch (e:any) {
				setSubmitError(e.message||'Error has occurred');
			}
		}
	};
	const initForm:Array<FormField>  = [
		{
			name:'accountName',
			required: true
		},
		{
			name:'accountNumber',
			default:'000123456789',
			required: true
		},
		{
			name:'accountRoutingNumber',
			default:'110000000',
			required: true
		},
		{
			name:'address',
			required: true
		},{
			name:'addressLine2',
			required: false
		},
		{
			name:'phone',
			required: true
		},
		{
			name:'taxId',
			default:'000000000',
			required: true
		}
	];

	const {data:formData,errors,handleSubmitForm,onChangeInput,onChangeField,loading:submitLoading}:FormHook
		=useForm(initForm, createBankAccountAction);
	if(loading) return <p>Loading...</p>;
	if(error) return <p>Error...</p>;

	return <BankAccountRender
		errors={{...errors,submitError}}
		submitLoading={submitLoading}
		formData={formData}
		onChangeField={onChangeField}
		onChangeInput={onChangeInput}
		onSubmit={handleSubmitForm}
	/>;
};
