import React from 'react';

interface StarIconProps {
	active:boolean
	size?:number
	activeColor?:string
	className?:string
}

export default function StarIcon({active,size=16,activeColor='#F9A326',className}:StarIconProps):JSX.Element{

	return (
		<svg
			className={className}
			width={size}
			height={size}
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M14.4334 0H1.56664C0.70141 0 0 0.70141 0 1.56664V14.4334C0 15.2986 0.70141 16 1.56664 16H14.4334C15.2986 16 16 15.2986 16 14.4334V1.56664C16 0.70141 15.2986 0 14.4334 0Z"
				fill={active?activeColor:'#D5DADB'}
			/>
			<path
				d="M8 2.71036L9.20923 6.43337H13.1288L9.95979 8.73865L11.169 12.4617L8 10.1564L4.83693 12.4617L6.04616 8.73865L2.87714 6.43337H6.79076L8 2.71036Z"
				fill="white"
			/>
		</svg>
	);
}
