import React, {useContext, useEffect} from 'react';
import {Route, Routes} from 'react-router-dom';
import {Analytics, Login, Orders, PlaceDetail, Places, Signup, Users} from './pages';
import {Dashboard, OnBoarding} from './containers';
import {BankAccount, Company} from './containers/OnBoarding/components';
import {UserContext} from './context/userContext';
import {EnvContext} from './context/envContext';
import {ProtectedRoute} from './components/ProtectedRoute';

function App():JSX.Element{
	const {getEnv}:any=useContext(EnvContext);
	const {refresh}:any=useContext(UserContext);
	useEffect(():void=>{
		refresh();
		getEnv();
	},[]);
	return (
		<Routes>
			<Route path='/' element={<Login/>}/>
			<Route path='/login' element={<Login/>}/>
			<Route path='/signup' element={<Signup/>}/>
			{/* OnBoarding */}
			<Route path='/onboarding' element={
				<OnBoarding/>
			}>
				<Route path='1' element={<Company/>}/>
				<Route path='2' element={<BankAccount/>}/>
			</Route>

			{/* DashBoard */}
			<Route path='/dashboard' element={
				<ProtectedRoute redirectTo={'/login'}>
					<Dashboard/>
				</ProtectedRoute>
			}>
				<Route path='places' element={<Places/>}/> 			{/* todo: delete this path*/}
				<Route path='places/:id' element={<PlaceDetail/>}/> {/* todo: delete this path*/}
				<Route path='locations' element={<Places/>}/>
				<Route path='locations/:id' element={<PlaceDetail/>}/>

				<Route path='users' element={<Users/>}/>
				<Route path='analytics' element={<Analytics/>}/>
				<Route path='orders' element={<Orders/>}/>
			</Route>
		</Routes>
	);
}

export default App;
