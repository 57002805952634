import React, {ChangeEvent, useEffect} from 'react';
import AddressAutoCompleteRender from './AddressAutoCompleteRender';
import {PlaceAutoComplete} from '../../../interfaces';
import usePlaceAutocomplete, {GooglePlaceAtuComplete} from '../../../hooks/usePlaceAutocomplete';

export const AddressAutoComplete=({onChange,name,placesTypes,onChangeInput,...props}:PlaceAutoComplete):JSX.Element=>{
	const onPlaceChanged=():void=>{
		console.log('changed');
	};
	const {autocompleteInput,googlePlace}:GooglePlaceAtuComplete=
		usePlaceAutocomplete(placesTypes,onPlaceChanged);

	useEffect(():void=>{
		if(onChange){
			onChange(googlePlace?.place_id,name);
		}
	},[googlePlace]);


	return <AddressAutoCompleteRender
		refInput={autocompleteInput}
		{...props}
		onChangeInput={onChangeInput}
	/>;
};
