import React from 'react';
// @ts-ignore
import {Message} from '@moadigitalworks/boutique-app/dist/components/ui';
import {Popup} from 'portals/Popup';
import {FormButton,Input} from 'components/ui';
import {color} from 'styles/colors';
import {FormProps, Item} from 'interfaces';

interface PopupRenderProps extends FormProps{
	formRef:any
	places?:Item[]
	placeId?:boolean
}


export default function InviteValetRender({formRef,errors,formData,submitLoading,onChangeInput,onSubmit}:PopupRenderProps):JSX.Element {
	return (
		<Popup>
			<div
				className='grows w-100 flex direction-column align-center justify-center pver-2 '
				style={{backgroundColor:'rgba(0,0,0,0.8)'}}
			>
				<div
					ref={formRef}
					style={{alignSelf:'center'}}
					className='w-400px white t-black br flex direction-column'
				>
					<form
						className='w-100 flex direction-column align-center pt-3 pr pb-2 pl'
						onSubmit={onSubmit}
					>
						<h4 className='f-large'>Invite Valet</h4>
						<Input
							labelColor={color.get('grey500')}
							label='First Name'
							placeholder='First Name'
							name='firstName'
							value={formData.firstName||''}
							onChange={onChangeInput}
						/>
						<Input
							labelColor={color.get('grey500')}
							label='Last Name'
							placeholder='Last Name'
							name='lastName'
							value={formData.lastName||''}
							onChange={onChangeInput}
						/>
						<Input
							labelColor={color.get('grey500')}
							name='email'
							label = 'Email'
							placeholder='Email'
							value={formData.email||''}
							onChange={onChangeInput}
						/>


						<FormButton
							submitLoading={submitLoading}
							spinnerColor={color.get('green-valay')}
							className='w-100'
							color={color.get('green-valay')}
						>
							Invite
						</FormButton>
						{errors?<div className='mt'>
							{errors.firstName?.required && <Message message='First name is required' color='red'/>}
							{errors.lastName?.required && <Message message='Last name is required' color='red'/>}
							{errors.place?.required && <Message message='Please select a place' color='red'/>}
							{errors.email?.required && <Message message='Email is required' color='red'/>}
							{errors.email?.pattern && <Message message='Please add a valid email' color='red'/>}
							{errors.submitError && <Message message={errors.submitError} color='red'/>}
						</div>: null}
					</form>
				</div>
			</div>
		</Popup>
	);
}
