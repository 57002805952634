import React, {Ref} from 'react';
import {PlaceAutoComplete} from 'interfaces';
import {color} from 'styles/colors';

interface AutoCompleteRender extends Omit<PlaceAutoComplete,'placesTypes'> {
	refInput:Ref<HTMLInputElement>
}

export default function AddressAutoCompleteRender({refInput,id,labelColor,value,label,placeholder,onChangeInput,onBlur,onFocus}:AutoCompleteRender):JSX.Element{

	return (
		<div
			style={{
				display:'flex',
				width: '100%',
				flexDirection:'column'
			}}
		>
			{label &&<div style={{
				padding: '8px 0',
				alignSelf:'self-start',
				fontSize: '0.75rem',
				color: labelColor|| color.get('white'),
				fontWeight: '700'
			}}>
				<label>{label}</label>
			</div>}
			<input
				id={id}
				onChange={onChangeInput}
				placeholder={placeholder||'Search on Google Places'}
				value={value}
				ref={refInput}
				onBlur={onBlur}
				onFocus={onFocus}
				style={{
					textAlign:'center',
					width:'100%',

					height:'48px',
					backgroundColor:'#fff',

					borderRadius: '4px',
					lineHeight:'1.5rem',
					color: color.get('grey400'),
					border: '1px solid #F3F5F5'
				}}
			/>
		</div>
	);
}
