import React from 'react';
import {Header,SideBar} from '../../components';
import {Outlet} from 'react-router-dom';

export default function DashboardRender():JSX.Element{
	return (
		<>
			<Header/>
			<div className='w-100 grows flex justify-start'>
				<SideBar

				/>
				<main className='white grows flex direction-column t-black pt-2 pr-2 pl-3'>
					<Outlet/>
				</main>

			</div>
		</>
	);
}