import React from 'react';
import {createPortal} from 'react-dom';

export interface Portal {
	target:HTMLElement|null
	children:JSX.Element
}

export default function PopupPortalRender({target,children}:Portal):JSX.Element {
	if(!target) return <p>Error</p>;
	return createPortal(
		<div
			id ={'pop-up'}
			className= 'fixed flex direction-column align-center top-0 z-100 w-100vw min-h-100vh h-100 transparent overflow-x-scroll'>
			{children}
		</div>
		,target
	);
}
