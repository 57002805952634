import React from 'react';
import {LogoType} from '../../LogoType';

export interface AuthProps {
	children:JSX.Element
}

export default function AuthLayoutRender({children}:AuthProps):JSX.Element{
	return (
		<>
			<LogoType/>
			<main className='w-100 max-w-1200px flex direction-column align-center mb-2 phor-2'>
				{children}
			</main>
		</>
	);
}
