import React from 'react';

// @ts-ignore
export interface RaitingRenderProps {
	stars: Array<JSX.Element>
}
export default function RatingRender({stars}:RaitingRenderProps):JSX.Element{
	return (
		<div
			className='flex'
		>
			{stars}
		</div>
	);
}
