import React from 'react';
import {Logo} from '../Logo';
import {VSymbol} from '../VSymbol';

export default function LogoTypeRender():JSX.Element{
	return (
		<div className='w-100 flex direction-column align-center mt-4 mb-2'>
			<VSymbol className='mb'/>
			<Logo/>
		</div>
	);
}