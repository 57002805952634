import React, {FunctionComponent} from 'react';
import PopupPortalRender from './PopupPortalRender';

interface PopupProps{
	children:JSX.Element
	className:string
}
export const PopupPortalActions:FunctionComponent<any> = ({children}:PopupProps):JSX.Element=>{
	let target:HTMLElement|null = document.getElementById('pop-up');
	return <PopupPortalRender target={target} >
		{children}
	</PopupPortalRender>;
};

