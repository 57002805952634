import React, {createContext, useState} from 'react';

interface EnvProviderProps {
    children:JSX.Element
}
export interface PlacesContextValue {
	isValet?: boolean
	setIsValet:(value:boolean)=>void
}
export const PlacesContext:React.Context<PlacesContextValue> =createContext({setIsValet:(value :boolean):void =>console.log()});
const PlacesContextProvider=({children}:EnvProviderProps):JSX.Element=>{
	const [isValet,setIsValet]:any =useState<boolean|null>(true);
	const value:PlacesContextValue={
		isValet,
		setIsValet
	};
	return (
		<PlacesContext.Provider value={value} >
			{children}
		</PlacesContext.Provider>
	);
};
export default PlacesContextProvider;
