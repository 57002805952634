import React from 'react';

export interface PlaceIconProps{
	valet:boolean
}
export default function PlaceIconRender({valet}:PlaceIconProps):JSX.Element {
	if(!valet) {
		return (
			<svg
				style={{minWidth: '28px'}}
				width={27}
				height={32}
				viewBox="0 0 27 32"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g style={{mixBlendMode: 'multiply'}}>
					<g style={{mixBlendMode: 'multiply'}}>
						<path
							d="M13.5 26.5108C20.9558 26.5108 27 20.5761 27 13.2554C27 5.93464 20.9558 0 13.5 0C6.04416 0 0 5.93464 0 13.2554C0 20.5761 6.04416 26.5108 13.5 26.5108Z"
							fill="#171F42"
						/>
					</g>
					<g style={{mixBlendMode: 'multiply'}}>
						<path
							d="M13.5012 13.2516L3.95523 22.6245L13.5012 31.9975L23.0471 22.6245L13.5012 13.2516Z"
							fill="#171F42"
						/>
					</g>
					<g style={{mixBlendMode: 'multiply'}}>
						<path
							d="M13.5 26.5108C20.9558 26.5108 27 20.5761 27 13.2554C27 5.93464 20.9558 0 13.5 0C6.04416 0 0 5.93464 0 13.2554C0 20.5761 6.04416 26.5108 13.5 26.5108Z"
							fill="#171F42"
						/>
					</g>
					<g style={{mixBlendMode: 'multiply'}}>
						<path
							d="M13.5012 13.2516L3.95523 22.6245L13.5012 31.9975L23.0471 22.6245L13.5012 13.2516Z"
							fill="#171F42"
						/>
					</g>
				</g>
				<path
					d="M13.5 23.2933C19.0998 23.2933 23.6394 18.836 23.6394 13.3376C23.6394 7.83926 19.0998 3.38195 13.5 3.38195C7.90016 3.38195 3.3606 7.83926 3.3606 13.3376C3.3606 18.836 7.90016 23.2933 13.5 23.2933Z"
					fill="#2DB9E7"
				/>
				<path
					d="M14.3821 9H10V19H12.5446V10.9539H13.7092C14.7836 10.9539 15.42 11.5011 15.42 12.5499C15.42 13.5987 14.7813 14.1459 13.7 14.1459H13.0955V16.0645H14.2361C16.4625 16.0645 18 14.6726 18 12.5442C17.9875 10.4045 16.5309 9 14.3821 9Z"
					fill="white"
				/>
			</svg>

		);
	}
	else{
		return (
			<svg
				style={{minWidth: '28px'}}
				width={27}
				height={32}
				viewBox="0 0 27 32"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g style={{ mixBlendMode: 'multiply' }}>
					<g style={{ mixBlendMode: 'multiply' }}>
						<path
							d="M13.5 26.5108C20.9558 26.5108 27 20.5761 27 13.2554C27 5.93464 20.9558 0 13.5 0C6.04416 0 0 5.93464 0 13.2554C0 20.5761 6.04416 26.5108 13.5 26.5108Z"
							fill="#171F42"
						/>
					</g>
					<g style={{ mixBlendMode: 'multiply' }}>
						<path
							d="M13.5012 13.2516L3.95523 22.6245L13.5012 31.9975L23.0471 22.6245L13.5012 13.2516Z"
							fill="#171F42"
						/>
					</g>
					<g style={{ mixBlendMode: 'multiply' }}>
						<path
							d="M13.5 26.5108C20.9558 26.5108 27 20.5761 27 13.2554C27 5.93464 20.9558 0 13.5 0C6.04416 0 0 5.93464 0 13.2554C0 20.5761 6.04416 26.5108 13.5 26.5108Z"
							fill="#171F42"
						/>
					</g>
					<g style={{ mixBlendMode: 'multiply' }}>
						<path
							d="M13.5012 13.2516L3.95523 22.6245L13.5012 31.9975L23.0471 22.6245L13.5012 13.2516Z"
							fill="#171F42"
						/>
					</g>
				</g>
				<path
					d="M13.5 23.2933C19.0998 23.2933 23.6394 18.836 23.6394 13.3376C23.6394 7.83926 19.0998 3.38195 13.5 3.38195C7.90017 3.38195 3.36061 7.83926 3.36061 13.3376C3.36061 18.836 7.90017 23.2933 13.5 23.2933Z"
					fill="#00B5AC"
				/>
				<path
					d="M13.5052 19.0025C13.4796 18.9733 13.4586 18.9409 13.4427 18.9062L12.7871 17.7189C12.6033 17.398 12.4194 17.045 12.2286 16.7112C12.0864 16.4641 11.9546 16.2138 11.8158 15.9635C11.632 15.6426 11.4516 15.2896 11.2608 14.9591C11.1151 14.7056 10.9902 14.4456 10.8376 14.1953C10.685 13.945 10.5462 13.6883 10.4109 13.4284C10.2201 13.0658 10.0189 12.7063 9.81772 12.3469C9.69284 12.1255 9.56101 11.9041 9.44654 11.6762C9.2835 11.3553 9.09965 11.0601 8.94008 10.7488C8.81867 10.521 8.69725 10.2931 8.56197 10.0685C8.4683 9.91126 8.37811 9.74759 8.29139 9.58714C8.20467 9.42669 8.09019 9.22131 8 9.03518C8.01734 9.0063 8.04162 9.01593 8.06244 9.01593H10.8549C10.8786 9.01286 10.9027 9.0172 10.9235 9.02824C10.9442 9.03928 10.9604 9.05641 10.9694 9.0769C11.2226 9.55184 11.4932 10.0172 11.743 10.4921C11.8574 10.7071 11.9823 10.9189 12.0899 11.1339C12.1419 11.227 12.1835 11.3232 12.2356 11.4131C12.35 11.6217 12.4715 11.8271 12.5825 12.0324L13.2346 13.2198C13.3977 13.515 13.5538 13.8135 13.7237 14.1055C13.8937 14.3975 14.0359 14.7152 14.2129 15.0072C14.4522 15.4116 14.6742 15.8255 14.9066 16.2363C14.9297 16.2705 14.9419 16.3099 14.9419 16.3502C14.9419 16.3905 14.9297 16.43 14.9066 16.4641C14.6881 16.8492 14.4765 17.2375 14.2614 17.6226L13.5225 18.9544L13.5052 19.0025Z"
					fill="white"
				/>
				<path
					d="M15.3229 15.6747C15.2917 15.6234 15.2674 15.5849 15.2466 15.5464L14.4869 14.1633C14.3482 13.9162 14.2025 13.6691 14.0706 13.4188C14.0117 13.3032 13.9458 13.1909 13.8833 13.0786C13.869 13.0563 13.8614 13.0308 13.8614 13.0048C13.8614 12.9788 13.869 12.9533 13.8833 12.931C14.1366 12.4753 14.3828 12.0164 14.6395 11.5575C14.7957 11.2783 14.9448 10.9959 15.1044 10.7167C15.264 10.4375 15.4513 10.0749 15.6247 9.77648C15.7565 9.54222 15.8849 9.30475 16.0132 9.07049C16.0204 9.04905 16.0354 9.03059 16.0556 9.01829C16.0759 9.00598 16.1002 9.00061 16.1243 9.0031H18.9341H19L15.3229 15.6747Z"
					fill="white"
				/>
			</svg>
		);
	}
}
