import React from 'react';
// @ts-ignore
import {Select} from '@moadigitalworks/boutique-app/dist/components/ui';
import {SelectProps} from '../../../interfaces';
import {color} from '../../../styles/colors';

interface SelectPlaceTypeProps extends SelectProps{
	isValet:string
}

export default function SelectPlaceTypeRender({isValet,options,onChange,...props}:SelectPlaceTypeProps):JSX.Element{

	return (
		<Select
			options={options}
			name='valet'
			onChange={onChange}
			value={isValet}
			{...props}
			styles={{
				height:'48px',
				width:'156px',
				color:'#0A1130',
				borderRadius:'52px',
				borderColor:'rgba(213, 218, 219, 0.32)',
				lineHeight:'1.5rem',
				fontColor: color.get('white'),

				labelFontSize:'0.75rem',
				labelFontColor: color.get('white'),
				labelFontWeight:'700'
			}}
		/>
	);
}
