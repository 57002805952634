import React from 'react';
import DashboardRender from './DashboardRender';
import {Location, Navigate, useLocation} from 'react-router-dom';

export const Dashboard = ():JSX.Element=>{
	const {pathname}: Location=useLocation();
	if(pathname.split('/').pop() === 'dashboard'){
		return<Navigate to={'/dashboard/places'}/>;
	}
	return <DashboardRender />;
};
