import gql from 'graphql-tag';

const mutations:any ={
	'updateUser':gql`
	mutation updateUser($firstName:String, $lastName:String, $role:String) {
      updateUser(firstName: $firstName, lastName: $lastName, role: $role) {
        company {
          createdAt
          id
          name
          stripeId
          updatedAt
        }
        email
        emailVerified
        firstName
        id
        lastName
        updatedAt
      }
    }`,
	'createCompany':gql`
	mutation createCompany($name:String!)  {
	  createCompany(name:$name) {
		createdAt
		id
		name
		stripeId
		updatedAt
	  }
	}`,
	'createCompanyBankAccount':gql`
	mutation createCompanyBankAccount(
	  $accountName: String!,
	   $ accountNumber: String!,
		$accountRoutingNumber:String!,
		$address: String!,
		$addressLine2:String,
		$id: String!,
		$phone: String!,
		$taxId: String!,
	) {
	  createCompanyBankAccount(
		accountName: $accountName
		accountNumber: $accountNumber
		accountRoutingNumber: $accountRoutingNumber
		address: $address
		addressLine2: $addressLine2
		id: $id
		phone: $phone
		taxId: $taxId
	  ) {
		createdAt
		id
		name
		stripeId
		updatedAt
	  }
	}`,
	'setDefaultCompany':gql`
	mutation setDefaultCompany($company:String!) {
	  setDefaultCompany(company: $company) {
		createdAt
		id
		name
		stripeId
		updatedAt
	  }
	}`,
	'createPlace':gql`
	mutation createPlace($hourlyRate:Float!, $placeId: String!, $valet: Boolean!) {
	  createPlace(hourlyRate:$hourlyRate, placeId: $placeId, valet: $valet) {
		addressComponents {
		  longName
		  shortName
		  types
		}
		administrativeAreaLevel1
		administrativeAreaLevel2
		auxDistanceMeters
		available
		city
		company {
		  createdAt
		  id
		  name
		  stripeId
		  updatedAt
		}
		country
		cover {
		  large
		  small
		}
		createdAt
		description
		email
		formattedAddress
		hourlyRate
		id
		location {
		  coordinates
		  type
		}
		name
		numberOfReviews
		phone
		placeId
		rating
		timeZone
		types
		updatedAt
		valet
		website
	  }
	}`,
	'InviteValet':gql`
	mutation CreateValet($email:String!,$firstName:String!,$lastName:String!,$place:String!) {
	  createCompanyUser(
		email: $email,
		firstName: $firstName
		lastName: $lastName
		place: $place
	  ) {
		company {
		  id
		  name
		}
		user{
		  email
		}
		createdAt
		id
		userRole {
		  createdAt
		  id
		  label
		  updatedAt
		}
	  }
	}`
};


export const mutation=(value:any):any=>{
	return mutations[value];
};




