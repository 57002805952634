import React from 'react';
import {Order} from 'interfaces';
// @ts-ignore
import {CardBase} from '@moadigitalworks/boutique-app/dist/components';

interface OrdersRenderProps {
	orders:Array<Order>
}

export default function OrdersRender({orders}:OrdersRenderProps):JSX.Element{
	return (
		<>
			<h4>Orders</h4>

			<CardBase
				className='flex direction-column h-400px mt-3 mb-2 mobile:grid2 '
				$width='100%'
				padding='40px 12px 16px 12px'
			>
				<table>
					<thead>
						<tr>
							<th>Name</th>
							<th>Email</th>
							<th>Total</th>
							<th>Paid</th>
							<th>Created At</th>
							<th>Transaction Id</th>
						</tr>
					</thead>
					<tbody>
						{orders.map(({name,email,total,paid,createdAt,transactionId}:Order):JSX.Element=>
							<tr className={''} key={transactionId}>
								<td>{name}</td>
								<td>{email}</td>
								<td>{total}</td>
								<td>{paid?'yes':'No'}</td>
								<td>{createdAt}</td>
								<td>{transactionId}</td>
							</tr>
						)}

					</tbody>
				</table>
			</CardBase>


		</>
	);
}
