import React, {ChangeEvent} from 'react';
import SelectCompanyRender from './SelectCompanyRender';
import {Company, Item, SelectProps} from '../../../interfaces';
import {QueryResult, useMutation, useQuery} from '@apollo/client';
import {query} from '../../../actions/queries';
import {mutation} from '../../../actions/mutations';

export const SelectCompany = ({...props}:SelectProps):JSX.Element=>{
	const {data:userData,loading:userLogin,error:userError}:QueryResult =useQuery(query('getUserJwt'));
	// @ts-ignore
	const {data,loading,error}:QueryResult =useQuery(query('getCompanies'),
		{
			variables:{
				skip:0,
				limit:100
			}
		}
	);

	//
	//const [userUpdate]:any = useMutation(mutation('usersUpdateOne'));
	const [setDefaultCompany]:any=useMutation(mutation('setDefaultCompany'),{
		refetchQueries: [
			'getPlacesByCompany',
			'getUserJwt'
		]
	});
	const setOptions = (companies:Array<Company>):Array<Item> =>
		companies.map((company:Company):Item=> ({
			_id:company.id,
			label:company.name
		}));

	const change= ({target}:ChangeEvent<HTMLSelectElement>):void=>{
		setDefaultCompany({
			variables: {company:target.value}
		});
	};
	if(loading||userLogin) return <p>Loading...</p>;
	if(error||userError) return <p>Error...</p>;

	return <SelectCompanyRender
		currentCompany={userData.getUserJwt.company.id}
		{...props}
		options={setOptions(data.getCompanies)}
		onChange={change}
	/>;
};
